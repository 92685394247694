.cardContainer {
  width: 100%;
  overflow: hidden;
  border-radius: 6px;
  color: var(--color-background);
  background-color: var(--color-secondary);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
  .imageContainer {
    position: relative;
    width: 100%;
    height: 100%;
    .image {
      width: 100%;
      height: 220px;
      object-fit: cover;
      object-position: center;
    }
  }
  .playIcon {
    position: absolute;
    background-color: var(--color-background);
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    opacity: 1;
    left: 50%;
    height: 40px;
    width: 40px;
    border-radius: 40px;
    padding: 14px;
    overflow: hidden;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    .playIcon > svg {
      height: 100%;
      width: auto;
    }
  }
  .cardContent {
    padding: 20px;
    .data {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .category {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 0.6rem;
      color: var(--color-background) !important;
      letter-spacing: 1.8px;
    }
  }
}

.date {
  font-size: 0.6rem;
  margin: 0;
}

.title {
  color: var(--color-background) !important;
  display: block;
  margin-top: 10px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  height: 72px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  margin-bottom: 10px;
}

.cardContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  overflow: hidden;
  border-radius: 6px;
  color: var(--color-text);
  background-color: var(--color-background);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
  align-items: center;
  .imageContainer {
    position: relative;
    height: 100%;
    width: 100%;
    .image {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    @media (max-width: 768px) {
      height: 280px;
    }
  }
  .cardContent {
    padding: 30px 36px;
    .data {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 40px;
      .category {
        text-transform: uppercase;
        color: var(--color-secondary) !important;
        font-weight: 700;
        font-size: 0.6rem;
        letter-spacing: 1.8px;
      }
      .date {
        font-size: 0.6rem;
      }
      @media (max-width: 768px) {
        margin-bottom: 0;
      }
    }
    .title {
      margin-top: 30px;
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 1.1em;
      color: var(--color-text) !important;
      display: block;
      @media (max-width: 992px) {
        margin-right: 0;
      }
      @media (max-width: 768px) {
        margin-top: 8px;
      }
    }
    .content {
      color: var(--color-text) !important;
      display: block;
      margin-top: 40px;
      margin-bottom: 0;
      font-size: 0.9rem;
      line-height: 1.4em;
      margin-right: 70px;
      font-weight: 400;
      @media (max-width: 992px) {
        margin-right: 0;
      }
      @media (max-width: 768px) {
        margin-top: 20px;
      }
    }
    .duration {
      font-size: 0.75rem;
      text-align: right;
      font-weight: 600;
      margin: 0;
    }
    @media (max-width: 768px) {
      padding: 20px 36px;
    }
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

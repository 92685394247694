.container {
  border-radius: 6px;
  height: 100% !important;
  overflow: hidden;
  box-shadow: 0 20px 15px 0 rgba(0, 0, 0, 0.04),
    0 13.3px 13.8px 0 rgba(0, 0, 0, 0.08), 0 6.7px 12px 0 rgba(0, 0, 0, 0.06);
  .location {
    padding: 18px 24px;
    background-color: var(--color-secondary);
    display: flex;
    align-items: center;
    justify-content: center;
    .locationLabel {
      margin-bottom: 0;
      margin-left: 18px;
      font-size: 26px;
      font-weight: 600;
      line-height: 40px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .date {
    padding: 18px 24px;
    background-color: var(--color-background);
    display: flex;
    justify-content: center;
    align-items: center;
    .dateLabel {
      margin-bottom: 0;
      margin-left: 18px;
      font-size: 20px;
      line-height: 24px;
      font-weight: 600;
      color: var(--color-text);
      @media (max-width: 340px) {
        font-size: 16px;
        line-height: 22px;
        font-weight: 600;
      }
    }
  }
  .iconContainer {
    height: 40px;
    width: 32px;
    position: relative;
    @media (max-width: 340px) {
      font-size: 16px;
      line-height: 22px;
      font-weight: 600;
    }
    .icon {
      position: absolute;
      width: 100%;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
    }
  }
}

.container {
  border-top: solid 2px #bcbcbc;
  margin-top: -32px;
  padding-top: 32px;
  border-bottom: solid 2px #bcbcbc;
}

.value {
  padding-right: 5px;
}
.labelRadio {
  height: 22px;
}
.indicator[type="radio"]:checked,
.indicator[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.indicator[type="radio"]:checked + label,
.indicator[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  /* display: inline-block; */
  /* color: red; */
}
.indicator[type="radio"]:checked + label:before,
.indicator[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 22px;
  height: 22px;
  border: 2px solid var(--color-primary);
  border-radius: 100%;
  background: #fff;
}
.indicator[type="radio"]:checked + label:after,
.indicator[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: var(--color-primary);
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.indicator[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.indicator[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.title {
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  margin-bottom: 16px;
}

.content {
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
}

.questionWrapper {
  margin-bottom: 32px;
}

.questionTitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
}

.optionWrapper {
  display: flex;
  margin-bottom: 16px;
}

.indicator {
  margin-right: 16px;
  height: 24px;
  width: 24px;
  color: var(--color-primary);
}

.label {
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  margin-top: 4px;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 32px;
}

.prevButton {
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  padding: 12px 24px;
  background-color: var(--color-primary);
  color: var(--color-background);
  border-radius: 8px;
  border: none;
}

.prevButton:disabled {
  opacity: 0.4;
}

.prevButton:hover {
  color: var(--color-background);
  background-color: var(--color-secondary);
}

.success .label {
  color: rgba(59, 170, 130, 1);
  font-weight: 700;
  position: relative;
}

.success .label::after {
  height: 12px;
  width: 16px;
  background: url(../../../images/success.svg);
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -32px;
  background-repeat: no-repeat;
}

.mistake .label {
  color: rgba(234, 59, 59, 1);
  font-weight: 700;
  position: relative;
}

.mistake .label::after {
  height: 12px;
  width: 12px;
  background: url(../../../images/mistake.svg);
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -32px;
  background-repeat: no-repeat;
}

.inputsWrapper {
  margin: 0 -16px;
  display: flex;
  flex-wrap: wrap;
}

.input {
  font-size: 14px;
  line-height: 40px;
  padding: 0 16px;
  border-radius: 8px;
  border: solid 2px rgba(0, 159, 227, 1);
  margin: 16px;
  height: 42px;
}

.rateWrapper {
  display: flex;
  align-items: flex-end;
  margin-bottom: 42px;
}

.rateMessage {
  margin-bottom: 0px;
}

.valuesWrapper {
  display: flex;
  flex: 1;
}

.valueWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.valueWrapper .indicator {
  margin: 0;
  cursor: pointer;
}

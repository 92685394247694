.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 992px) {
    flex-direction: column;
    align-items: stretch;
    max-width: 480px;
    margin: 0 auto;
    max-height: 100%;
    justify-content: flex-start;
    overflow-y: auto;
  }
  .itemContainer {
    display: flex;
    margin: 0 12px;
    padding: 20px 0;
    position: relative;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 992px) {
      display: grid;
      grid-template-columns: auto 1fr;
      padding: 0;
      &.open {
        grid-template-columns: 24px 1fr;
        .back {
          @media (max-width: 992px) {
            display: block;
            width: 24px;
          }
        }
        .subItemsContainer {
          padding: 10px 64px;
          max-height: none !important;
          overflow-y: auto;
          border-top: solid 3px var(--color-primary) !important;
          padding: 0;
        }
        .arrow {
          @media (max-width: 992px) {
            display: none;
          }
        }
      }
      &.invisible {
        display: none;
      }
    }
    &:hover {
      cursor: pointer;
      .item {
        color: var(--color-primary);
      }
      .arrow {
        color: var(--color-primary);
      }
      .subItemsContainer {
        padding: 10px 64px;
        max-height: 600px;
        overflow-y: auto;
        border-top: solid 3px var(--color-primary);
        @media (max-width: 992px) {
          max-height: 0;
          border: none;
          overflow: hidden;
          padding: 0px;
        }
      }
    }
    &:first-of-type {
      margin-left: 0;
      @media (max-width: 992px) {
        margin-left: 12px;
      }
    }
    &:last-of-type {
      margin-right: 0;
      @media (max-width: 992px) {
        margin-right: 12px;
      }
    }
    .back {
      display: none;
      transform: rotateZ(90deg);
    }
    .item {
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      transition: 0.3s ease;
      @media (max-width: 992px) {
        font-size: 24px;
        padding: 20px 0;
        margin-left: 16px;
      }
    }
    .arrow {
      height: 12px;
      margin-left: 12px;
      transition: 0.3s ease;
      @media (max-width: 992px) {
        height: 24px;
        width: 24px;
        justify-self: end;
        transform: rotateZ(-90deg);
      }
    }
    .subItemsContainer {
      position: absolute;
      top: 100%;
      left: 50%;
      padding: 0 64px;
      background-color: var(--color-background);
      box-shadow: 0 5px 5px rgb(0 0 0 / 20%);
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      overflow: hidden;
      max-height: 0;
      width: max-content;
      max-width: 400px;
      transform: translateX(-50%);
      transition: 0.3s ease;
      @media (max-width: 992px) {
        grid-column: 1 / 3;
        position: relative;
        top: 0;
        width: 100%;
        background-color: transparent;
        box-shadow: none;
        max-width: none;
        padding: 0;
        overflow-y: auto;

        max-height: 0;
        border: none;
        overflow: hidden;
        padding: 0px;
      }
      .subItem {
        display: block;
        color: var(--color-primary);
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        margin: 10px 0;
        transition: 0.3s ease;
        @media (max-width: 992px) {
          font-size: 24px;
          padding: 12px 0;
        }
        &:hover {
          color: var(--color-secondary);
        }
      }
    }
    &:first-of-type > .subItemsContainer {
      left: 0;
      transform: translateX(0);
      @media (max-width: 992px) {
        left: 0;
        transform: translateX(0);
      }
    }
    &:last-of-type > .subItemsContainer {
      left: 100%;
      transform: translateX(-100%);
      @media (max-width: 992px) {
        left: 0;
        transform: translateX(0);
      }
    }
  }
}

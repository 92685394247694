.container {
  border-radius: 6px;
  background-color: #f1f1f1;
  display: flex;
  align-items: stretch;
  padding-right: 24px;
  transition: 0.3s ease;
  overflow: hidden;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
    padding-bottom: 0;
    padding-right: 0;
    height: 100%;
  }
  &:hover {
    background-color: var(--color-primary);
    .content {
      .data {
        .category {
          color: var(--color-background) !important;
        }
        .date {
          color: var(--color-background) !important;
        }
      }
      .title {
        color: var(--color-background) !important;
      }
      .description {
        color: var(--color-background) !important;
      }
      .duration {
        color: var(--color-background) !important;
      }
    }
  }
  .imageContainer {
    display: block;
    .image {
      height: 100%;
      width: 200px;
      object-fit: cover;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
  .content {
    margin-left: 32px;
    padding: 16px 0;
    @media (max-width: 768px) {
      padding-right: 24px;
      margin-left: 24px;
    }
    .data {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      .category {
        text-transform: uppercase;
        color: var(--color-secondary) !important;
        font-weight: 700;
        font-size: 0.6rem;
        letter-spacing: 1.8px;
      }
      .date {
        font-size: 0.6rem;
        font-weight: 500;
        margin: 0;
      }
    }
    .title {
      display: block;
      position: relative;
      font-size: 20px;
      line-height: 24px;
      color: var(--color-text) !important;
      height: 48px;
      font-weight: 600;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .description {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      position: relative;
      margin-top: 20px;
      margin-bottom: 0;
      font-size: 14px;
      line-height: 20px;
      height: 60px;
      font-weight: 400;
      overflow: hidden;
      text-overflow: ellipsis;
      color: var(--color-text) !important;
      -webkit-line-clamp: 3;
    }
    .duration {
      font-size: 0.7rem;
      text-align: right;
      font-weight: 600;
      padding-top: 20px;
      margin: 0;
    }
  }
}

.cardContainer {
  width: 100%;
  overflow: hidden;
  border-radius: 6px;
  color: var(--color-background);
  background-color: var(--color-primary);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
}

.imageContainer {
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
}

.image {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
}

.title {
  position: absolute;
  bottom: 24px;
  right: 100px;
  left: 0;
  padding: 10px 20px;
  line-height: 40px;
  font-size: 32px;
  font-weight: 600;
  text-align: right;
  color: var(--color-background) !important;
  background-color: var(--color-primary);
}

.cardContent {
  padding: 38px 29px 43px 36px;
  display: flex;
  align-items: center;
}

.description {
  flex: 1;
  height: 72px;
  margin-top: 0px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: var(--color-background) !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
}

.icon {
  width: 72px;
  height: 72px;
  padding: 12px;
  margin-left: 54px;
  overflow: hidden;
  border-radius: 40px;
  background-color: var(--color-background);
}

.icon > svg > g > path {
  fill: var(--color-secondary);
}

.directoryContainer {
  width: 100%;
  display: flex;
  align-items: center;
}

.pathLink {
  font-size: 0.7rem;
  font-weight: 600;
  text-transform: capitalize;
  color: var(--color-primary) !important;
}

.pathLink:last-of-type {
  font-weight: 400;
}

.separator {
  height: 0.7rem;
  margin: 0 15px;
}

.postColumn {
  padding: 0 80px;
}

.imageContainer {
  width: 100%;
  position: relative;
  transform: translateY(-50%);
  top: 50%;
}

.mainImage {
  width: 100%;
  height: auto;
  margin-top: 50px;
  border-radius: 6px;
}

.mainTitle {
  color: var(--color-text);
  margin-top: 24px;
  font-weight: 800;
  font-size: 50px;
}

.bookTitle {
  position: absolute;
  left: 0;
  bottom: 30px;
  right: 70px;
  font-size: 32px;
  font-weight: 600;
  line-height: 1.25;
  text-align: right;
  color: var(--color-background);
  background: var(--color-primary);
  padding: 10px 30px;
}

.mainSubtitle {
  margin-top: 24px;
  font-size: 28px;
  font-weight: 600;
  line-height: 1.14;
}

.sectionDescription {
  margin-top: 51px;
  font-size: 18px;
  line-height: 1.78;
}

.doubleSideFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sectionTitle {
  margin: 40px 0;
  font-weight: 700;
  font-size: 2rem;
}

.moreArticles {
  color: var(--color-primary);
  text-decoration: underline;
  text-align: right;
}
.moreArticles:hover {
  color: var(--color-secondary);
}
.verticalFlex {
  display: flex;
  flex-direction: column;
}

.button {
  padding: 6px 36px;
  font-weight: 600;
  font-size: 1.2rem;
  border-radius: 6px;
  border: none;
  background-color: var(--color-primary);
  color: var(--color-background);
}

@media (max-width: 768px) {
  .download {
    display: block;
    text-align: center;
  }
}

.bgContainer {
    background-color: white;
    box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05),
        0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 40px;
    margin-bottom: 36px;
    margin-top: 40px;
}
.header {
    font-weight: 700;
    font-size: 20px;
    line-height: 40px;
    color: #000000;
    text-align: center;
    padding-bottom: 20px;
}

.content {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
}
.text {
    margin-left: 16px;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
    max-width: 85%;
}
.imageContainer {
    width: 50px !important;
    height: 50px !important;
}

@media screen and (max-width: 576px) {
    .text {
        max-width: 70% !important;
    }
}

.dropDownContainer {
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: black;
    border: 2px solid #dff3fb;
    padding: 8px 16px;
    border-radius: 8px;
    position: relative;
}
.dropDownOptionsWrapper {
    position: absolute;
    top: 37px;
    z-index: 2;
    background-color: white;
    width: 100.4%;
    left: -2px;
    border: 2px solid #dff3fb;
    border-top: none;
    border-radius: 8px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: 1px;
}
@media screen and (max-width: 1200px) {
    .dropDownOptionsWrapper {
        width: 100.5%;
    }
}
@media screen and (max-width: 992px) {
    .dropDownOptionsWrapper {
        width: 100.6%;
    }
    .dropDownContainer {
        margin-bottom: 20px;
    }
    .selectTitle {
        font-size: 24px !important;
        line-height: 28px !important;
    }
    .selectSubtitle {
        margin-bottom: 20px !important;
    }
}
@media screen and (max-width: 768px) {
    .dropDownOptionsWrapper {
        width: 100.8%;
    }
}
@media screen and (max-width: 576px) {
    .dropDownOptionsWrapper {
        width: 100.9%;
    }
}
.dropDownHeader {
    display: flex;
    justify-content: space-between;
}
.options {
    border-top: 2px solid #dff3fb;
    width: 100%;
    padding: 6px 16px;
    color: black;
    font-weight: 400;
    font-size: 14px;
    background-color: white;
    cursor: pointer;
}
.options:hover {
    font-weight: 700 !important;
    color: var(--color-primary) !important;
    background: #f9fdfe !important;
}
.imageOpened {
    transform: rotate(180deg);
}
.headerOpened {
    color: #767676 !important;
}
.selectBg {
    background: #ffffff;
    box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05),
        0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 40px;
    margin: 24px 0 24px 0;
}
.selectTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 40px;
}
.selectSubtitle {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 40px;
}

.selectButton {
    background: var(--color-primary);
    border-radius: 8px;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #ffffff;
    border: none;
    padding: 11px 40px;
}

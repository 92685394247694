.postWrapper .gallery {
  border-radius: 6px;
}

.postWrapper .swiper-slide {
  padding-top: 56.25%;
}

.postWrapper .swiper-slide > img {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  object-fit: contain;
}

.postWrapper .arrow-left {
  height: 24px;
  width: 24px;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  left: 32px;
  z-index: 1;
  cursor: pointer;
  filter: drop-shadow(0 0 0.75rem #000);
}

.postWrapper .arrow-right {
  height: 24px;
  width: 24px;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: 32px;
  z-index: 1;
  cursor: pointer;
  filter: drop-shadow(0 0 0.75rem #000);
}

.postWrapper .gallery {
  position: relative;
}

.directoryContainer {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 24px;
}

.pathLink {
  font-size: 14px;
  font-weight: 600;
  text-transform: none;
  color: var(--color-primary) !important;
}

span:last-of-type > .pathLink {
  font-weight: 400;
}

.separator {
  height: 14px;
  margin: 0 15px;
}

.background {
  background: rgba(0, 159, 227, 0.2);
}

.title {
  font-weight: 800;
  font-size: 38px;
  line-height: 48px;
  color: var(--color-primary);
  margin-bottom: 16px;
  margin-top: 66px;
}
.subTitle {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 40px;
}

.content b {
  color: var(--color-primary);
}

.content > b {
  margin-bottom: 2.2em;
  display: block;
}

.content a {
  color: var(--color-primary);
  font-weight: 600;
  text-decoration: underline;
}

.content p {
  margin-bottom: 24px;
  font-size: 18px;
  line-height: 32px;
}

.content p.disclaimer {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-top: 54px;
  margin-bottom: 54px;
}

.content .button {
  border: none;
  padding: 12px 24px;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  font-weight: 700;
  text-decoration: none;
  display: block;
  background-color: var(--color-primary);
  border-radius: 8px;
  color: var(--color-background);
  margin-bottom: 88px;
  transition: 0.3s ease;
}

.content .button:hover {
  background-color: var(--color-secondary);
}

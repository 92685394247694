.hedaerWrapper {
}

.chaptersWrapper {
    display: flex;
    overflow-x: auto;
    padding-bottom: 16px;
}

.chaptersWrapper .chapterWrapper:first-of-type {
    margin-left: 0;
}

.chaptersWrapper > .chapterWrapper:last-of-type::before,
.chaptersWrapper > .chapterWrapper:last-of-type::after {
    display: none;
}

.completed .chapterLinkWrapper {
    color: #57c0ed;
    border: solid 2px #57c0ed;
    border-radius: 10px;
}

.completed::after {
    background-color: var(--color-primary) !important;
}

.chapterWrapper {
    color: #c3e8f8;
    font-size: 20px;
    line-height: 32px;
    font-weight: 600;
    border-radius: 10px;
    background-color: var(--color-background);
    border: solid 1px #c3e8f8;
    margin-left: 40px;
    position: relative;
    height: 100%;
}
.chapterLinkWrapper:hover {
    background-color: var(--color-primary) !important;
    color: white !important;
    border-radius: 10px;
    cursor: pointer;
}
.chapterWrapper.activeChapter::before {
    position: absolute;
    width: 8px;
    height: 2px;
    background-color: var(--color-primary);
    content: '';
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}
.activeChapter .chapterLinkWrapper {
    border-color: var(--color-primary) !important;
    color: var(--color-primary);
    border-radius: 10px;
}
/* .completed {
    border: solid 2px #57c0ed;
    color: #57c0ed;
    border-radius: 12px;
} */

.chapterWrapper::after {
    position: absolute;
    width: 42px;
    height: 2px;
    background-color: #c3e8f8;
    content: '';
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
}

.chapterWrapper.completed::after {
    opacity: 1;
}

.chapterLink {
    display: block;
    padding: 10px 16px;
    border-radius: 8px;
    position: relative;
    white-space: nowrap;
}

.chapterLinkWrapper {
    position: relative;
    z-index: 1;
}

.chapterWrapper.activeChapter .chapterLink::after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid var(--color-primary);
    transform: translateX(-50%);
    top: 100%;
    left: 50%;
}

.activeChapter::after {
    background-color: #c3e8f8 !important;
}

.modulesHolder {
    position: relative;
}

.modulesHolder::after {
    position: absolute;
    top: 0;
    height: 100%;
    left: 50%;
    width: 100vw;
    content: '';
    transform: translateX(-50%);
    background-color: var(--color-background);
}

.modulesWrapper {
    margin-top: 20px;
    padding: 16px 0;
    background-color: var(--color-background);
    display: flex;
    overflow-x: auto;
}

.moduleWrapper {
    display: flex;
    min-width: 160px;
    width: 160px;
    position: relative;

    z-index: 1;
}

.passed .number {
    background-color: var(--color-primary);
    border: solid 3px rgba(87, 192, 237, 0.6);
}

.passed .moduleTitle {
    color: var(--color-text);
}

.passed .moduleContent {
    color: var(--color-text);
}

.number {
    background-color: #bcbcbc;
    height: 24px;
    width: 24px;
    min-width: 24px;
    min-height: 24px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 600;
    line-height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-background);
    border: solid 3px rgba(210, 210, 210, 1);
    margin-right: 8px;
}

.moduleTitle {
    font-size: 13px;
    line-height: 26px;
    font-weight: 600;
    margin-bottom: 4px;
    color: #bcbcbc;
}

.moduleContent {
    margin-bottom: 0;
    font-size: 13px;
    line-height: 16px;
    color: #bcbcbc;
    width: 100%;
    padding-right: 24px;
}

.stepperTitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.spacer {
  height: 222px;
  @media (max-width: 992px) {
    height: 160px;
  }
  .wrapper {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 10000;
    @media (max-width: 992px) {
      max-height: 160px;
      overflow: hidden;
      &.open {
        max-height: 100%;
        height: 100vh;
      }
    }
    .container {
      display: grid;
      grid-template-columns: auto 1fr auto;
      position: relative;
      @media (max-width: 992px) {
        grid-template-columns: 1fr auto;
        grid-template-rows: auto auto 1fr auto;
        height: 100%;
        padding-bottom: 24px;
      }
      &::after {
        position: absolute;
        content: "";
        top: 0;
        transform: translateX(-50%);
        left: 50%;
        z-index: -1;
        height: 100%;
        width: 100vw;
        background-color: var(--color-background);
      }
      .icon {
        display: none;
        @media (max-width: 992px) {
          display: block;
          width: 32px;
          position: absolute;
          right: 0;
          top: 58px;
          transform: translate(-50%, -50%);
          cursor: pointer;
        }
      }
      .logoContainer {
        .logo {
          height: 52px;
          width: auto;
          margin: 24px 0;
          @media (max-width: 992px) {
            margin: -5px 0;
            margin-top: 35px;
          }
          @media (max-width: 480px) {
            padding: 10px 0;
          }
        }
      }
      .searchContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 992px) {
          grid-row: 4 / 5;
          grid-column: 1 / 2;
        }
        .searchForm {
          height: 36px;
          padding: 5px;
          margin: 0 30px;
          display: flex;
          align-items: center;
          border: solid 2px var(--global-primary);
          border-radius: 20px;
          overflow: hidden;
          @media (max-width: 992px) {
            width: 100%;
          }
          .searchInput {
            font-size: 14px;
            margin-left: 5px;
            height: 100%;
            border: none;
            width: 160px;
            outline: none;
            color: var(--global-primary);
            transition: 0.3s ease-in-out;
            @media (max-width: 992px) {
              width: 100%;
            }
            &:focus {
              width: 300px;
              @media (max-width: 992px) {
                width: 100%;
              }
            }
            &::placeholder {
              color: var(--global-primary);
              font-weight: 200;
              opacity: 0.8;
            }
          }
          .searchIcon {
            height: 20px;
            width: auto;
            margin: 5px;
            cursor: pointer;
          }
        }
      }
      .statusContainer {
        display: flex;
        align-items: center;
        @media (max-width: 992px) {
          grid-row: 4 / 5;
          grid-column: 2 / 3;
        }
        .subLogo {
          height: 64px;
          margin-right: 32px;
          @media (max-width: 992px) {
            display: none;
          }
        }
        .userIconContainer {
          height: 36px;
          width: 36px;
          padding: 6px;
          border: solid 2px var(--global-primary);
          background-color: var(--global-primary);
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          .userIcon {
            width: auto;
            height: 100%;
          }
        }
      }
      .tabsContainer {
        grid-column: 1 / 4;
        display: flex;
        justify-content: center;
        position: relative;
        padding-bottom: 2px;
        &::after {
          content: "";
          height: 3px;
          width: 100vw;
          bottom: 0;
          position: absolute;
          background-color: var(--color-primary);
        }
        @media (max-width: 992px) {
          grid-column: 1 / 3;
          margin-top: 8px;
          margin-bottom: 24px;
          &::after {
            display: none;
          }
        }
        .tab {
          align-items: center;
          justify-content: center;
          max-width: 240px;
          width: 100%;
          padding: 14px 0;
          font-size: 18px;
          text-align: center;
          color: var(--color-primary);
          font-weight: 800;
          background-color: #f4f4f4;
          transition: 0.3s ease;
          cursor: pointer;
          &.active {
            background-color: var(--color-primary);
            color: var(--color-background);
          }
          &:first-of-type {
            border-top-left-radius: 20px;
            @media (max-width: 992px) {
              border-top-left-radius: 0;
            }
          }
          &:last-of-type {
            border-top-right-radius: 20px;
            @media (max-width: 992px) {
              border-top-right-radius: 0;
            }
          }
          .navLogo {
            max-width: 80%;
            max-height: 32px;
          }
        }
      }
      .categoriesContainer {
        grid-column: 1 / 4;
        @media (max-width: 992px) {
          grid-column: 1 / 3;
          height: 100%;
          overflow-y: auto;
        }
        .categoriesWrapper {
          @media (max-width: 992px) {
            height: 100%;
            overflow-y: auto;
          }
          overflow: visible;
        }
      }
    }
  }
}

.profileBadgeWrapper {
  position: relative;
  display: flex;
}
.notificationBellIcon {
  background: var(--global-primary);
  border-radius: 50%;
  padding: 3px;
  margin-right: 8px;
  position: relative;
  cursor: pointer;
}
.notificationsCount {
  width: auto;
  min-width: 24px;
  max-width: 35px;
  height: 24px;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(6px, -10px);
  z-index: 9;
  background: var(--color-secondary);
  color: #fff;
  border-radius: 50%;
  overflow: hidden;
  line-height: 22px;
  text-align: center;
  font-size: 13px;
  border: none;
}

.mobileNotificationsDot {
}
.mobileNotificationsDot::after {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--color-secondary);
  position: absolute;
  top: 0px;
  right: -3px;
  display: block;
}

.errorMsg {
  color: white;
  background-color: #dd4e4e;
  margin-bottom: 5px;
  padding: 4px 12px;
  border-radius: 6px;
}
.infoIcon {
  position: relative;
  top: 2px;
  padding-right: 5px;
}

.cardBg {
  background: #f2fbff;
  padding: 40px 112px;
  margin-top: 44px;
  /* margin-bottom: 64px; */
}
.smallText {
  font-weight: 700;
  font-size: 10px;
  line-height: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #c21b17;
}
@media screen and (max-width: 992px) {
  .cardBg {
    padding: 50px;
  }
  .cardTitle {
    font-size: 24px !important;
    line-height: 40px !important;
  }
  .textCheckbox {
    white-space: normal !important;
  }
}
@media screen and (max-width: 768px) {
  .cardBg {
    padding: 30px !important;
    margin-top: 20px;
    /* margin-bottom: 44px; */
  }
  .input {
    border-radius: 8px 8px 8px 8px !important;
  }
  .textCheckbox {
    white-space: normal !important;
  }
  .verticalCardButton {
    padding: 11px 20px !important;
    margin-top: 20px !important;
    border-radius: 8px 8px 8px 8px !important;
  }
}
.cardTitle {
  color: black;
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
  padding-bottom: 16px;
}
.privacyLink {
  /* text-decoration: none; */
  color: var(--color-primary);
}
.cardContent {
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: black;
  padding-bottom: 16px;
  word-spacing: 1px;
}

.input {
  border: 2px solid #bcbcbc;
  border-radius: 8px 0px 0px 8px;
}

.input::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 40px;
  color: #c2c2c2;
}

.verticalCardButton {
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: white;
  background: var(--color-primary);
  border: 2px solid #c21b17 !important;
  padding: 11px 52px;
  background: #c21b17 !important;
  border-radius: 0px 8px 8px 0px !important;
  margin-left: -10px;
}
.verticalCardButton:hover {
  background-color: var(--color-secondary) !important;
  border: 2px solid var(--color-secondary) !important;
  background: var(--color-secondary) !important;
}

.alignFlex {
  display: flex;
  position: relative;
}
.checkBoxLabel {
  display: flex;
  margin-top: 15px;
  /* align-items: center; */
}
.textCheckbox {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: black;
  margin-left: 16px;
  /* white-space: nowrap; */
}
.buttonText {
  white-space: nowrap;
}
.checkBoxLabel input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border: 2px solid var(--color-primary) !important;
  border-radius: 4px;
  position: relative;
}
/* .checkBoxLabel:hover input ~ .checkmark {
    background-color: #ccc;
} */
.checkBoxLabel input:checked ~ .checkmark {
  background-color: #fff;
}
.checkmark::after {
  content: "";
  position: absolute;
  display: none;
}
.checkBoxLabel input:checked ~ .checkmark::after {
  display: block;
}
.checkBoxLabel .checkmark::after {
  left: 3px;
  top: 0px;
  width: 5px;
  height: 10px;
  border: solid #2196f3;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.privacyLink {
  font-weight: 700;
  text-decoration: underline;
}
.privacyLink:hover {
  color: var(--color-secondary);
}

@font-face {
  font-family: Montserrat;
  src: url(fonts/Montserrat.woff2) format("truetype-variations");
  font-weight: 1 800;
  font-display: block;
}

:root {
  --global-primary: #009fe3;
  --global-secondary: #ebbc37;
  --color-text: #000000;
  --color-background: #ffffff;
}

html,
body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  color: var(--color-text);
  font-family: Montserrat, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
  &:hover {
    color: var(--color-primary);
  }
}

* {
  box-sizing: border-box;
  margin: 0;
}

.glossary-tooltip {
  position: relative;
  cursor: pointer;
}

.glossary-tooltip .glossary-link {
  pointer-events: none;
}

.glossary-tooltip:hover .glossary-link > a {
  color: var(--color-secondary);
}

.glossary-tooltip .glossary-tooltip-content {
  display: none;
}

.glossary-tooltip:hover .glossary-tooltip-content {
  display: block;
}

.glossary-tooltip-content {
  position: absolute;
  display: none;
  background-color: var(--color-primary);
  padding: 24px 18px;
  padding-bottom: 36px;
  font-size: 16px;
  font-weight: 400;
  color: var(--color-background);
  bottom: calc(100% + 20px);
  width: 400px;
  max-width: 100vw;
  left: 50%;
  line-height: 28px;
  border-radius: 6px;
  transform: translateX(-50%);
}

.glossary-tooltip:hover .glossary-tooltip-content::after {
  content: "";
  position: absolute;
  bottom: -12px;
  border-radius: 6px;
  height: 30px;
  width: 30px;
  transform: translateX(-50%) rotateZ(45deg);
  left: 50%;
  background-color: var(--color-primary);
}

.glossary-tooltip:hover .glossary-tooltip-content .glossary-tooltip-text > a {
  display: none;
}

h3 .glossary-tooltip-content {
  display: none !important;
}

h3 .glossary-link > a {
  color: var(--color-text) !important;
}

.special-table {
  border-collapse: collapse;
  overflow-x: auto;
  width: 100%;
}

.special-table td {
  border: solid 1px var(--color-text);
  padding: 16px;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  background-color: rgba(255, 255, 255, 0.06);
}

.special-table tr td:first-of-type {
  font-weight: 600;
  background-color: rgba(255, 255, 255, 0.4);
}

.special-table th {
  border: solid 1px var(--color-text);
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  padding: 16px;
  background-color: rgba(233, 182, 35, 0.3);
}

.table-desktop {
  display: block;
}
.table-mobile {
  display: none;
}

.table-mobile {
  list-style-type: none;
  padding: 0px;
}
.table-mobile li {
}
.table-mobile li .item-header {
  cursor: pointer;
  padding-right: 20px;
  position: relative;
  border: 1px solid #b9b9b9;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  padding: 16px;
  background-color: rgba(233, 182, 35, 0.3);
}
.table-mobile li .item-header .arrow {
  width: 16px;
  height: 10px;
  display: inline-block;
  background-image: url("./images/arrow-down.svg");
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}
.table-mobile li.opened .item-header .arrow {
  background-image: url("./images/arrow-up.svg");
}
.table-mobile li .item-content {
  display: none;
}
.table-mobile li .item-content .item-row {
  display: flex;
  flex-flow: row nowrap;
  border-bottom: 1px solid #b9b9b9;
}
.table-mobile li .item-content .item-row > div {
  padding: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  background-color: hsla(0, 0%, 100%, 0.06);
}
.table-mobile li .item-content .item-row > .item-label {
  font-weight: 700;
  background-color: hsla(0, 0%, 100%, 0.4);
  flex: 0 0 97px;
  border-left: 1px solid #b9b9b9;
  border-right: 1px solid #b9b9b9;
}
.table-mobile li .item-content .item-row .item-value {
  flex: 1;
  border-right: 1px solid #b9b9b9;
}

.table-mobile li.opened .item-content {
  display: initial;
}
.table-mobile li .item-content p {
  margin: 0 !important;
}

.instagramVideoIframe {
  max-width: 500px !important;
  height: 800px !important;
}

@media (max-width: 500px) {
  .instagramVideoIframe {
    height: 750px !important;
  }
}

@media (max-width: 992px) {
  .table-desktop {
    display: none;
  }
  .table-mobile {
    display: block;
  }
  .nav-link {
    padding: 12px;
    width: 100%;
  }
  .nav-item {
    margin-bottom: 0;
    flex: 1;
  }
  .nav-item:first-of-type {
    border-top-left-radius: 0 !important;
  }
  .nav-item:last-of-type {
    border-top-right-radius: 0 !important;
  }
}

.background-cyan {
  background-color: #ccecf9;
}

.articoloButton {
  background-color: var(--color-primary);
  color: #fff;
  border-radius: 4px;
  padding: 8px 32px;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  border-style: none;
}

.articoloButton:hover {
  background-color: var(--color-secondary);
  color: var(--color-background);
}

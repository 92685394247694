.directoryContainer {
  width: 100%;
  display: flex;
  align-items: center;
}

.pathLink {
  font-size: 0.7rem;
  font-weight: 600;
  text-transform: none;
  color: var(--color-primary) !important;
}

.pathLink:last-of-type {
  font-weight: 400;
}

.separator {
  height: 0.7rem;
  margin: 0 15px;
}

.mainTitle {
  color: var(--color-primary);
  margin-top: 30px;
  margin-bottom: 20px;
  font-weight: 800;
  font-size: 2rem;
}

.sectionDescription {
  font-size: 1.1rem;
  line-height: 1.6em;
  font-weight: 400;
  margin-bottom: 0;
}

.notFound {
  margin-top: 36px;
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
}

.formazioneTitle {
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  margin-bottom: 32px;
}

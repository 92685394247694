.errorMsg {
  color: white;
  background-color: #dd4e4e;
  margin-bottom: 5px;
  padding: 4px 12px;
  border-radius: 6px;
}
.infoIcon {
  position: relative;
  top: 2px;
  padding-right: 5px;
}

.cardBg {
  background: var(--color-primary);
  box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.2),
    0px 10px 15px -3px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 40px 112px;
  margin-top: 64px;
  margin-bottom: 64px;
}
.cardBgNoMargin {
  margin-top: 0px;
  margin-bottom: 0px;
}
@media screen and (max-width: 992px) {
  .cardBg {
    padding: 50px;
  }
  .cardTitle {
    font-size: 24px !important;
    line-height: 28px !important;
  }
}
@media screen and (max-width: 768px) {
  .cardBg {
    padding: 30px !important;
    margin-top: 20px;
    margin-bottom: 44px;
  }
  .verticalCardButton {
    margin-top: 10px;
  }
}
.cardTitle {
  color: #ffffff;
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  padding-bottom: 16px;
}
.cardContent {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  padding-bottom: 16px;
}

.input {
  border: 2px solid #fff !important;
}

.input::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 40px;
  color: #c2c2c2;
}

.verticalCardButton {
  font-weight: 700;
  font-size: 18px !important;
  line-height: 20px !important;
  color: white;
  background: var(--color-primary) !important;
  border: 1px solid white !important;
  padding: 11px 22px !important;
  border-radius: 6px !important;
  margin-left: -10px;
}
.verticalCardButton:hover {
  background-color: var(--color-secondary);
  border: 1px solid var(--color-secondary);
}

.alignFlex {
  display: flex;
  position: relative;
}
.checkBoxLabel {
  display: flex;
  margin-top: 15px;
  align-items: start;
}
.textCheckbox {
  font-weight: 300;
  font-size: 12px;
  /* line-height: 15px; */
  color: #fff;
  margin-left: 16px;
  margin-top: -3px;
  padding-bottom: 15px;
}
.checkBoxLabel input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  top: 0;
  left: 0;
  height: 12px;
  width: 12px;
  border: 1px solid #fff;
  position: relative;
}
/* .checkBoxLabel:hover input ~ .checkmark {
    background-color: #ccc;
} */
.checkBoxLabel input:checked ~ .checkmark {
  background-color: #fff;
}
.checkmark::after {
  content: "";
  position: absolute;
  display: none;
}
.checkBoxLabel input:checked ~ .checkmark::after {
  display: block;
}
.checkBoxLabel .checkmark::after {
  left: 3px;
  top: -1px;
  width: 5px;
  height: 10px;
  border: solid #2196f3;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.privacyLink {
  font-weight: 600;
  text-decoration: underline;
}
.privacyLink:hover {
  color: var(--color-secondary);
}

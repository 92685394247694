.cardContainer {
  width: 100%;
  overflow: hidden;
  border-radius: 6px;
  background-color: var(--color-background);
  color: var(--color-text);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
  .imageContainer {
    padding-top: 56.25%;
    width: 100%;
    position: relative;
    overflow: hidden;
    display: block;
    .thumbnailWrapImage {
      div {
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        display: block;
        overflow: hidden;
        position: absolute;
        box-sizing: border-box;
        margin: 0px;
      }
      img {
        object-fit: cover;
        object-position: center;
        position: absolute;
        inset: 0px;
        box-sizing: border-box;
        padding: 0px;
        border: none;
        margin: auto;
        display: block;
        width: 0px;
        height: 0px;
        min-width: 100%;
        max-width: 100%;
        min-height: 100%;
        max-height: 100%;
      }
    }
  }
  .cardContent {
    padding: 20px;
    .data {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      .category {
        text-transform: uppercase;
        color: var(--color-secondary) !important;
        font-weight: 700;
        font-size: 0.6rem;
        letter-spacing: 1.8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .date {
        font-size: 0.6rem;
        margin: 0;
      }
    }
    .title {
      display: block;
      position: relative;
      font-size: 20px;
      line-height: 24px;
      color: var(--color-text) !important;
      height: 72px;
      font-weight: 600;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .content {
      display: -webkit-box;
      position: relative;
      -webkit-box-orient: vertical;
      margin-top: 20px;
      margin-bottom: 0;
      font-size: 14px;
      line-height: 20px;
      height: 100px;
      font-weight: 400;
      overflow: hidden;
      text-overflow: ellipsis;
      color: var(--color-text) !important;
      -webkit-line-clamp: 5;
    }
    .duration {
      font-size: 0.7rem;
      text-align: right;
      font-weight: 600;
      padding-top: 20px;
      margin: 0;
    }
  }
}

.container {
    padding-top: 40px;
}

.title {
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 16px;
}
.optionWrapper .indicator[type='radio']:checked,
.optionWrapper .indicator[type='radio']:not(:checked) {
    position: absolute;
    left: -9999px;
}
.optionWrapper .indicator[type='radio']:checked + label,
.optionWrapper .indicator[type='radio']:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    /* display: inline-block; */
    /* color: red; */
}
.optionWrapper .indicator[type='radio']:checked + label:before,
.optionWrapper .indicator[type='radio']:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 22px;
    height: 22px;
    border: 2px solid var(--color-primary);
    border-radius: 100%;
    background: #fff;
}
.optionWrapper .indicator[type='radio']:checked + label:after,
.optionWrapper .indicator[type='radio']:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: var(--color-primary);
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.optionWrapper .indicator[type='radio']:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.optionWrapper .indicator[type='radio']:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.subtitle {
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 32px;
}

.optionWrapper {
    display: flex;
    margin-bottom: 16px;
    align-items: flex-start;
}

.indicator {
    margin-right: 16px;
    min-height: 24px;
    min-width: 24px;
    color: var(--color-primary);
}

.label {
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    margin-top: 4px;
}

.buttonsContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    margin-bottom: 64px;
}

.prevButton {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    padding: 12px 24px;
    background-color: var(--color-primary);
    color: var(--color-background);
    border-radius: 8px;
    border: 0;
}
.prevButton:disabled {
    opacity: 0.4;
}
.prevButton:hover {
    color: var(--color-background);
    background-color: var(--color-secondary);
}

.cardContainer {
  display: flex;
  width: 100%;
  overflow: hidden;
  border-radius: 6px;
  color: var(--color-text);
  background-color: var(--color-background);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
  align-items: stretch;
}

.imageContainer {
  position: relative;
  flex-basis: 200px;
}

.imageContainer > .image {
  height: 100%;
  width: 100%;
  min-width: 100px;
  min-height: 50px;
}

.imageContainer img {
  object-fit: cover;
  object-position: center;
}

.cardContent {
  padding: 20px 36px;
  flex: 1;
}

.data {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.category {
  text-transform: uppercase;
  color: var(--color-secondary) !important;
  font-weight: 700;
  font-size: 0.6rem;
  margin: 0;
  letter-spacing: 1.8px;
}

.date {
  font-size: 0.6rem;
  margin: 0;
}

.title {
  margin-top: 10px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  height: 72px;
  overflow: hidden;
  color: var(--color-text) !important;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.duration {
  font-size: 0.7rem;
  text-align: right;
  font-weight: 600;
  margin: 0;
}

@media (max-width: 992px) {
  .cardContainer {
    display: grid;
    grid-template-columns: 160px auto;
    margin: 20px 0;
  }

  .title {
    font-size: 16px;
    line-height: 18px;
    height: 54px;
  }

  .imageContainer {
    flex-basis: 180px !important;
  }
}

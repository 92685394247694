.background {
  background: var(--color-primary);
  padding: 100px 0;
}

.form {
  border-radius: 6px;
  box-shadow: 7px 8px 34px 16px rgba(0, 0, 0, 0.1);
  background: var(--color-background);
  padding: 72px;
  max-width: 600px;
  width: 100%;
  margin: auto;
}

.title {
  font-size: 38px;
  font-weight: 800;
  line-height: 50px;
  text-align: center;
  color: #009fe3;
}

.description {
  font-size: 18px;
  margin: auto;
  margin-top: 12px;
  max-width: 350px;
  line-height: 32px;
  text-align: center;
  margin-bottom: 45px;
}

.error {
  background-color: #dd4e4e;
  color: whitesmoke;
  border: solid 1px #cfcfcf;
  padding: 4px 12px;
  margin: 4px 0;
  border-radius: 6px;
  text-transform: capitalize;
}

.error:last-of-type {
  margin-bottom: 20px;
}

.succes {
  background-color: #4edd55;
  color: whitesmoke;
  border: solid 1px #cfcfcf;
  padding: 4px 12px;
  margin: 4px 0;
  border-radius: 6px;
  margin-bottom: 20px;
}

.label {
  display: block;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  margin-bottom: 6px;
}

.input {
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  border-radius: 6px;
  padding: 12px 15px;
  margin-bottom: 24px;
  background: #f6f6f6;
  border: solid 1px #9a9a9a;
}

.input::placeholder {
  font-weight: 500;
  color: #8e8e8e;
}

.alignFlex {
  display: flex;
  align-items: center;
}

.note {
  margin-left: 12px;
  font-size: 12px;
  line-height: 32px;
}

.justifyGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 22px;
}

.button {
  border: none;
  width: 100%;
  color: var(--color-background);
  font-size: 16px;
  border-radius: 6px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  padding: 12px 36px;
  background-color: var(--color-primary);
}

.button:disabled {
  opacity: 0.6;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.button > svg {
  height: 20px;
  width: auto;
  animation: rotate 2s infinite linear;
}

.moduleNumber {
  margin-top: 40px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.errorMessage {
  font-size: 16px;
  color: white;
  z-index: 9;
  background-color: #ea3b3b;
  padding: 3px 15px 3px 10px;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
}
.contentWrapper blockquote {
  font-style: italic !important;
  border-left: 2px #ebbc37 solid;
  padding-left: 40px;
  margin-left: 40px;
  margin-right:40px;
}
.contentWrapper blockquote p {
  color: #000000;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 32px !important;
}

.contentWrapper blockquote a {
  font-style: italic !important;
  color: #c21b17;
  text-decoration: underline;
  font-weight: bold;
}

.title {
  color: var(--color-primary);
  font-weight: 800;
  font-size: 38px;
  line-height: 48px;
}
@media only screen and (max-width: 992px) {
  .title {
      font-size: 32px;
      line-height: 40px;
  }
  .contentWrapper blockquote {
      padding-left: 20px;
      margin-left: 15px;
      margin-right:15px;
  }
}

.durationContainer {
  padding: 16px 0;
  border-bottom: solid 2px #bcbcbc;
}

.durationLabel {
  color: #bcbcbc;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

.duration {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
input .indicator {
  border: 1px solid red;
}

.contentWrapper {
  margin-top: 32px;
  margin-bottom: 24px;
  overflow-x: auto;
  height: max-content;
  overflow: visible;
}

.contentWrapper p {
  margin-bottom: 24px;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
}

.contentWrapper img,
.contentWrapper video,
.contentWrapper iframe {
  width: 100%;
  height: auto;
  margin-bottom: 24px;
}

.contentWrapper ol {
  list-style: none;
  counter-reset: section;
}

.contentWrapper ol li {
  counter-increment: section;
  position: relative;
  padding-left: 24px;
  margin-bottom: 24px;
}

.contentWrapper ol li::before {
  content: counter(section);
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  min-width: 40px;
  position: absolute;
  background-color: var(--color-secondary);
  border-radius: 20px;
  color: var(--color-background);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  left: -30px;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
  /* margin-bottom: 64px; */
  flex-wrap: wrap;
  position: relative;
  bottom: 0px;
  background-color: #ccecf9;
}
@media only screen and (max-width: 768px) {
  .buttonsContainer {
      position: sticky;
  }
}

.prevButton {
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  padding: 12px 24px;
  background-color: var(--color-background);
  color: var(--color-primary);
  border-radius: 8px;
  margin-bottom: 16px;
  margin-top: 16px;
}

.prevButton:hover {
  color: var(--color-secondary);
}

.nextButton {
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  padding: 12px 24px;
  background-color: var(--color-primary);
  color: var(--color-background);
  border-radius: 8px;
  border: none;
  margin-bottom: 16px;
  margin-top: 16px;
}

.nextButton:hover {
  color: var(--color-background);
  background-color: var(--color-secondary);
}

.privacyContainer {
  border-top: solid 2px #bcbcbc;
  padding-top: 40px;
  padding-bottom: 24px;
}

.checkboxWrapper {
  display: flex;
  align-items: flex-start;
}

.checkbox {
  min-height: 20px;
  min-width: 20px;
}

.checkboxLabel {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-top: 2px;
  padding-left: 16px;
  margin-bottom: 18px;
  cursor: pointer;
}

.checkboxLabel > a {
  font-weight: 700;
  color: var(--color-primary);
  text-decoration: underline;
}

.container {
  .bannerContent {
    color: #f0f0f0;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    .bannerIcon {
      margin-right: 24px;
      @media (max-width: 500px) {
        margin-right: 12px;
        width: 24px;
        height: 24px;
      }
    }
    .title {
      font-size: 40px;
      line-height: 40px;
      margin-bottom: 0;
      font-weight: 700;
      @media (max-width: 500px) {
        font-size: 18px;
      }
    }
    .description {
      grid-column: 2 / 3;
      margin-top: 2px;
      margin-right: 5px;
      @media (max-width: 500px) {
        font-size: 14px;
      }
    }
  }
  .largeMargin {
    margin: 80px 0;
  }
  .horizontalGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 0;
    @media (max-width: 992px) {
      grid-template-columns: 1fr;
    }
    .horizontalGridSection {
      margin-right: 20px;
      @media (max-width: 992px) {
        margin-right: 0;
      }
    }
    .horizontalGridSectionFlex {
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      @media (max-width: 992px) {
        margin-left: 0;
      }
      .verticalFlex {
        height: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
  .doubleSideFlex {
    display: flex;
    max-width: 100vw;
    margin-bottom: 40px;
    align-items: flex-end;
    justify-content: space-between;
    .sectionTitle {
      margin: 0;
      font-weight: 700;
      font-size: 1.8rem;
      text-overflow: ellipsis;
      overflow: hidden;
      color: var(--color-text) !important;
    }
    .moreArticles {
      color: var(--color-primary);
      text-decoration: underline;
      text-align: right;
      flex: 0 1 auto;
      position: relative;
      bottom: 4px;
      white-space: nowrap;
      cursor: pointer;
      &:hover {
        color: var(--color-secondary);
      }
    }
  }
  .banner {
    width: 100%;
    border-radius: 6px;
    margin-bottom: 80px;
  }
  .productSectionContainer {
    padding-top: 80px;
    position: relative;
    &::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 30%;
      background-color: var(--color-secondary);
      top: 0;
      // margin-top: -1em;
      z-index: -1;
    }
  }
  .imageDesktop {
    background-image: url(../../images/tovaglioli.jpg);
    width: 500em;
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 1.5rem;
    @media (max-width: 992px) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: initial;
    }
    .first {
      grid-column: 1 / 3;
      grid-row: 1 / 2;
      @media (max-width: 992px) {
        grid-column: 1 / 3;
        grid-row: 1 / 2;
      }
    }
    .second {
      grid-column: 1 / 3;
      grid-row: 2 / 3;
      @media (max-width: 992px) {
        grid-column: 1 / 3;
        grid-row: 2 / 3;
      }
    }
    .third {
      grid-column: 3 / 4;
      grid-row: 1 / 3;
      @media (max-width: 992px) {
        grid-column: 1 / 2;
        grid-row: 3 / 4;
      }
      @media (max-width: 580px) {
        grid-column: 1 / 3;
        grid-row: 3 / 4;
      }
    }
    .forth {
      grid-column: 4 / 5;
      grid-row: 1 / 3;
      @media (max-width: 992px) {
        grid-column: 2 / 3;
        grid-row: 3 / 4;
      }
      @media (max-width: 580px) {
        grid-column: 1 / 3;
        grid-row: 4 / 5;
      }
    }
  }
  .bottomBanner {
    width: 100%;
    border-radius: 6px;
    margin: 48px 0;
  }
  .gray {
    background-color: #f1f1f1;
    padding-top: 80px;
    .preFooterContainer {
      margin-top: 80px;
      .preFooterContent {
        padding: 64px 32px;
        color: var(--color-background);
        position: relative;
        &::after {
          position: absolute;
          content: "";
          background-color: var(--color-secondary);
          height: 100%;
          width: 100vw;
          right: 0;
          top: 0;
        }
        .preFooterTitle {
          position: relative;
          font-size: 32px;
          line-height: 40px;
          margin-bottom: 32px;
          font-weight: 700;
          z-index: 1;
        }
        .preFooterDescription {
          position: relative;
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 0;
          z-index: 1;
        }
      }
    }
    .logosContainer {
      height: 100%;
      padding: 24px 54px;
      padding-right: 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-content: center;
      grid-gap: 32px 52px;
      @media (max-width: 992px) {
        padding: 48px 0;
      }
      @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (max-width: 450px) {
        grid-template-columns: 1fr;
      }
      .logoCard {
        display: flex;
        align-items: center;
        justify-content: center;
        height: min-content;
        overflow: hidden;
        background-color: var(--color-background);
        border-radius: 6px;
        padding: 12px 24px;
        box-shadow: 0 20px 15px 0 rgba(0, 0, 0, 0.04),
          0 13.3px 13.8px 0 rgba(0, 0, 0, 0.08),
          0 6.7px 12px 0 rgba(0, 0, 0, 0.06);
        .logo {
          height: 48px;
          width: 100%;
          object-fit: contain;
        }
      }
      .logoText {
        color: #04416a;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
      }
    }
  }
}

.zucchettiBanner {
  width: 100%;
}

.sliderContainer {
  max-width: 540px;
  width: 100%;
  margin: auto;
  padding: 0px 48px;
  position: relative;
  .iconContainer {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    &.rightIcon {
      right: 0;
    }
    &.leftIcon {
      left: 0;
    }
    .icon {
      height: 48px;
    }
  }
  .sliderContent {
    flex: 1;
    overflow: hidden;
    .sliderWrapper {
      .imageContainer {
        position: relative;
        transition: 0.3s ease;
        overflow: hidden;
        .image {
          transition: 0.3s ease;
          height: 100%;
          width: 100%;
          border-radius: 6px;
          box-shadow: 0 20px 15px 0 rgb(0 0 0 / 4%),
            0 13.3px 13.8px 0 rgb(0 0 0 / 8%), 0 6.7px 12px 0 rgb(0 0 0 / 6%);
        }
      }
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1030px) {
  .sliderContainer {
    max-width: 100%;
  }
}

@media screen and (min-width: 1030px) {
  .sliderContainer {
    max-width: 100%;
    padding: 0px;
    .sliderContent {
      margin-left: -12px;
      margin-right: -12px;
    }
    .imageContainer {
      padding: 30px 12px;
    }
    .iconContainer {
      &.rightIcon {
        right: -48px;
      }
      &.leftIcon {
        left: -48px;
      }
    }
  }
}

.tfrWrapper .title,
.assegnoUnicoWrapper .title {
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
}

.tfrWrapper .content,
.assegnoUnicoWrapper .content {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 0;
}

.tfrWrapper .articoloButton,
.assegnoUnicoWrapper .articoloButton {
  background-color: var(--color-primary);
  color: #fff;
  border-radius: 4px;
  padding: 8px 32px;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  border-style: none;
}

.tfrWrapper .articoloButton:hover,
.assegnoUnicoWrapper .articoloButton:hover {
  background-color: var(--color-secondary);
  color: var(--color-background);
}

.tfrWrapper img,
.assegnoUnicoWrapper img {
  max-width: 100%;
}

@media screen and (min-width: 992px) {
  .tfrWrapper .imageWrapper {
    display: flex;
  }
  .tfrWrapper img,
  .assegnoUnicoWrapper img {
    margin: 0px auto;
  }
}

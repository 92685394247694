.container {
  position: fixed;
  bottom: 32px;
  right: 32px;
  height: 64px;
  width: 64px;
  background-color: var(--color-secondary);
  display: flex;
  justify-content: center;
  padding: 12px;
  border-radius: 32px;
  z-index: 1000;
  cursor: pointer;
  filter: drop-shadow(0px 5px 20px rgb(0 0 0 / 20%));
  transition: 0.3s ease;
  .icon {
    height: 100%;
  }
}

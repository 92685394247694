.notificationListContainer {
  position: absolute;
  top: 40px;
  right: -5px;
  left: auto;
  background: #fff;
  z-index: 999;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  width: 240px;
  max-width: calc(100vw - 30px);
  max-height: calc(100vh - 100px);
  border: 1px solid rgba(0, 0, 0, 0.05);
  color: #050505;

  @media all and (max-width: 992px) {
    top: initial;
    bottom: 50px;
  }
}

.notificationListContainer:after,
.notificationListContainer:before {
  bottom: 100%;
  right: 14px;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  display: none;
}

.notificationListContainer:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 8px;
  margin-left: -8px;
}
.notificationListContainer:before {
  border-color: rgba(0, 0, 0, 0);
  border-bottom-color: rgba(0, 0, 0, 0.05);
  border-width: 9px;
  margin-left: -9px;
}

.notificationListInner {
  overflow-x: hidden;
  padding: 10px;
}
.notificationBoxHeader {
  font-size: 16px;
  font-weight: bold;
  color: #050505;
  padding: 0 5px 5px;
}
.notificationLoading {
  font-size: 14px;
  padding: 5px;
}

.listUL {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.listLI {
  margin: 0;
  padding: 0;
}

.notificationLink {
  padding: 6px 10px;
  width: 100%;
  display: block;
  overflow: hidden;
  border-radius: 6px;
  color: #050505;
  cursor: pointer;
}
.notificationLink:hover {
  color: #050505;
}
.notificationLink:hover {
  background-color: #f2f4f9;
}
.notificationTitle {
  font-size: 14px;
  font-weight: bold;
}
.notificationBody {
  font-size: 13px;
}
.redDot {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: var(--color-secondary);
  border-radius: 50%; /* Makes it round */
  margin-right: 5px; /* Spacing between dot and title */
}

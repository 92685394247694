.container {
  border-radius: 6px;
  background-color: #f1f1f1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 16px;
  height: 100%;
  transition: 0.3s ease;
  overflow: hidden;
  @media (max-width: 768px) {
    align-items: stretch;
    padding-bottom: 0;
    height: 100%;
  }
  &:hover {
    background-color: var(--color-primary);
    .content {
      .data {
        .category {
          color: var(--color-background) !important;
        }
        .date {
          color: var(--color-background) !important;
        }
      }
      .title {
        color: var(--color-background) !important;
      }
      .description {
        color: var(--color-background) !important;
      }
      .duration {
        color: var(--color-background) !important;
      }
    }
  }
  .imageContainer {
    width: 100%;
    .image {
      height: 160px;
      @media (max-width: 992px) {
        height: 100%;
      }
      @media (max-width: 768px) {
        width: 100%;
      }
      width: 100%;
      object-fit: cover;
    }
  }
  .content {
    padding: 0 24px;
    margin-top: 28px;
    display: flex;
    flex: 1;
    flex-direction: column;
    @media (max-width: 768px) {
      margin-left: 32px;
      margin: 16px 0;
    }
    .data {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      .category {
        text-transform: uppercase;
        color: var(--color-secondary) !important;
        font-weight: 700;
        font-size: 0.6rem;
        letter-spacing: 1.8px;
      }
      .date {
        font-size: 0.6rem;
        font-weight: 500;
        margin: 0;
      }
    }
    .title {
      display: block;
      position: relative;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 0;
      color: var(--color-text) !important;
      height: 48px;
      font-weight: 600;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .description {
      flex: 1;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      position: relative;
      margin-top: 20px;
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 400;
      overflow: hidden;
      height: 120px;
      text-overflow: ellipsis;
      color: var(--color-text) !important;
      -webkit-line-clamp: 6;
      @media (max-width: 768px) {
        height: 60px;
        max-height: 60px;
        -webkit-line-clamp: 3;
        line-height: 20px;
      }
    }
    .duration {
      font-size: 0.7rem;
      text-align: right;
      font-weight: 600;
      padding-top: 20px;
      margin: 0;
    }
  }
}

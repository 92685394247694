.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
  padding: 32px 24px;
  height: 100%;
  background-color: var(--color-background);
  box-shadow: 0 20px 15px 0 rgba(0, 0, 0, 0.04),
    0 13.3px 13.8px 0 rgba(0, 0, 0, 0.08), 0 6.7px 12px 0 rgba(0, 0, 0, 0.06);
  .title {
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;
    width: 100%;
  }
  .sliderContainer {
    max-width: 540px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    .iconContainer {
      cursor: pointer;
      .icon {
        height: 48px;
      }
    }
    .sliderContent {
      flex: 1;
      overflow: hidden;
      .sliderWrapper {
        padding-top: 24px;
        padding-bottom: 40px;
        .imageContainer {
          position: relative;
          padding-top: 100%;
          border-radius: 50%;
          transition: 0.3s ease;
          overflow: hidden;

          &::after {
            border: solid 6px var(--color-background);
            position: absolute;
            content: "";
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            border-radius: 50%;
          }
          &.active {
            transform: scale(120%);
            box-shadow: 0 20px 15px 0 rgba(0, 0, 0, 0.04),
              0 13.3px 13.8px 0 rgba(0, 0, 0, 0.08),
              0 6.7px 12px 0 rgba(0, 0, 0, 0.06);
          }
          &.pasive {
            transform: scale(110%);
            .image {
              filter: blur(3px);
            }
          }
          .image {
            transition: 0.3s ease;
            position: absolute;
            object-fit: cover;
            height: 100%;
            width: 100%;
            top: 0;
          }
        }
      }
    }
  }
  .infoContainer {
    flex: 1;
    max-width: 620px;
    width: 100%;
    background-color: var(--color-primary);
    margin: 0 auto;
    padding: 12px 24px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    text-align: center;
    overflow: hidden;
    opacity: 1;
    transition: 0.3s ease;
    &.hidden {
      opacity: 0;
    }
    .fullName {
      font-size: 32px;
      font-weight: 700;
      line-height: 40px;
      margin-bottom: 12px;
      color: var(--color-background);
    }
    .position {
      font-size: 18px;
      font-weight: 600;
      line-height: 20px;
      margin-bottom: 8px;
      color: var(--color-background);
    }
    .description {
      font-size: 14px;
      margin-bottom: 0;
      line-height: 20px;
      color: var(--color-background);
    }
  }
}

.cardContainer {
  width: 100%;
  overflow: hidden;
  border-radius: 6px;
  color: var(--color-background);
  background-color: var(--color-primary);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
  .imageContainer {
    position: relative;
    width: 100%;
    height: 250px;
    .image {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .cardContent {
    padding: 24px 10px 12px 20px;
    display: flex;
    .title {
      display: block;
      flex: 1;
      margin-top: 10px;
      font-weight: 500;
      a {
        font-size: 20px;
        line-height: 24px;
        height: 48px;
        display: -webkit-box;
        text-overflow: ellipsis;
        overflow: hidden;
        color: var(--color-background) !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    .icon {
      margin-top: 35px;
      width: 70px;
      height: 70px;
      padding: 12px;
      overflow: hidden;
      border-radius: 40px;
      background-color: var(--color-background);
      fill: var(--color-secondary);
    }
  }
}

@media (max-width: 768px) {
  .small {
    display: flex;
    flex-direction: column;
    height: 100%;
    .imageContainer {
      height: 160px !important;
    }
    .cardContent {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 10px 10px;
    }
    .title {
      a {
        font-size: 18px !important;
        line-height: 22px;
        padding: 0;
        font-weight: 500;
        height: initial;
        -webkit-line-clamp: initial;
      }
    }
    .icon {
      align-self: flex-end;
      height: 50px;
      width: 50px;
      margin-top: 10px;
    }
  }
}

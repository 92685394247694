.container {
  .swiperWrapper {
    justify-content: stretch;
    .wrapper {
      height: initial;
      &:nth-last-of-type(n) {
        .cardContainer {
          background-color: var(--color-primary);
          color: var(--color-background);
        }
      }
      &:nth-last-of-type(2n) {
        .cardContainer {
          background-color: var(--color-secondary);
          color: var(--color-background);
        }
      }
      &:nth-last-of-type(3n) {
        .cardContainer {
          background-color: #f1f1f1;
          color: var(--color-primary);
        }
      }
      .cardContainer {
        padding: 12px 26px;
        height: 100%;
        .title {
          font-size: 26px;
          line-height: 32px;
          margin-bottom: 0;
          font-weight: 700;
        }
        .company {
          font-size: 16;
          line-height: 20px;
          font-weight: 700;
          margin-bottom: 0;
        }
        background-color: red;
      }
    }
  }
}

.cardContainer {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.sliderCard {
  position: relative;
  min-width: 90%;
  margin: 0 10px;
  transition: 0.3s ease-in-out;
}

.inTransition {
  transition: 0s;
}

.sliderCard:first-of-type {
  margin-left: 0;
}

.sliderCard:last-of-type {
  margin-right: 0;
}

.shade {
  position: relative;
  border-radius: 6px;
  overflow: hidden;
}

.moreText {
  position: absolute;
  z-index: 2;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-background) !important;
  font-size: 28px;
  font-weight: 600;
}

.moreText > svg {
  transform: rotateY(180deg);
  margin-left: 24px;
  height: 40px;
}

.shade::after {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  content: '';
  background: rgba(0, 0, 0, 0.4);
}

.moreImage {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.indicatorContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}

.indicator {
  height: 10px;
  width: 10px;
  margin: 5px;
  position: relative;
  border-radius: 5px;
  border: solid 1px var(--color-primary);
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.indicator.active {
  background-color: var(--color-primary);
}

.directoryContainer {
  width: 100%;
  display: flex;
  align-items: center;
}

.pathLink {
  font-size: 0.7rem;
  font-weight: 600;
  color: var(--color-primary) !important;
}

.pathLink:last-of-type {
  font-weight: 400;
}

.mainTitle {
  color: var(--color-primary);
  margin-top: 30px;
  margin-bottom: 20px;
  font-weight: 800;
  font-size: 2.4rem;
}

.sectionDescription {
  font-size: 1.1rem;
  line-height: 1.6em;
  font-weight: 400;
}

.doubleSideFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sectionTitle {
  margin-top: 30px 0;
  font-weight: 700;
  font-size: 2rem;
  color: var(--color-text);
}

.moreArticles {
  color: var(--color-primary);
  text-decoration: underline;
  text-align: right;
}
.moreArticles:hover {
  color: var(--color-secondary);
}

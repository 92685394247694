.container {
  padding: 40px 32px;
}

.bgWrapper {
  background: #ffffff;
  box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 40px 32px;
}

.title {
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  color: black;
  margin-bottom: 24px;
}

.subtitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 16px;
}
.quote {
  background: rgba(234, 174, 59, 0.1);
  border: 1px solid #eaaf3b;
  border-radius: 8px;
  padding: 8px;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.textWrapper h6 {
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  margin-top: 32px;
  color: var(--color-primary);
  margin-bottom: 16px;
}

.text {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  padding-top: 48px;
}

.contentWrapper {
  display: flex;
  margin-top: 32px;
}
.textFiledsWrapper {
  max-width: 50%;
  width: 100%;
}
.fieldsTitle {
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  color: var(--color-primary);
  min-height: 45px;
}
.fieldsSubtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #bcbcbc;
  min-height: 50px;
  padding-bottom: 16px;
}
.sliderWrapper {
  width: 50%;
}
.buttonWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 48px;
}
.calcolaButton {
  background: var(--color-primary);
  border: none;
  color: #fff;
  padding: 11px 41px;
  border-radius: 8px;
  /* opacity: 0.4; */
}

.input {
  width: 95%;
  mix-blend-mode: normal;
  /* opacity: 0.12; */
  border: 2px solid #009ee215;
  border-radius: 10px;
  background: #ffffff;
  /* box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05),
        0px 10px 15px -3px rgba(0, 0, 0, 0.1); */
  padding: 6px 12px;
  margin-right: 20px;
}
.input::placeholder {
  opacity: 1 !important;
}
/* .input :focus-visible {
    border: 2px solid #009ee215 !important;
} */
.input:focus-visible {
  outline: #009ee219 auto 1px !important;
}

.fields {
  display: flex;
  align-items: center;
}

.rangeSlider {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 15px;
  position: relative;
}
.number {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}
.slider {
  -webkit-appearance: none;
  top: -3px;
  position: relative;
  width: 100%;
  height: 4px;
  border-radius: 5px;
  background: #dff3fb;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: var(--color-primary);
  cursor: pointer;
}
.slider::-moz-range-thumb {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: var(--color-primary);
  cursor: pointer;
}

@media screen and (max-width: 992px) {
  .contentWrapper {
    flex-direction: column;
    justify-content: center;
  }
  .textFiledsWrapper {
    max-width: 100% !important;
  }
  .fieldsTitle {
    width: 100%;
  }
  .sliderWrapper {
    width: 100% !important;
    margin-top: 20px;
    margin-left: 0;
  }
  .input {
    width: 100% !important;
  }
  .rangeSlider {
    margin-top: -10px;
  }
}

.bubble {
  display: block !important;
  background: var(--color-background);
  color: white;
  padding: 8px;
  position: absolute;
  border-radius: 4px;
  border: 2px solid #d9f1fb;
  left: 50%;
  height: 40px;
  width: 44px;
  transform: translateX(-50%);
  color: var(--color-text);
  bottom: -40px;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
}
.rangeWrapper {
  width: 100%;
  padding: 0 10px 0 10px;
}

@media only screen and (max-width: 992px) {
  .title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 10px;
  }
  .subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 10px;
  }
}

.exception .swiper-slide {
  opacity: 0;
  transition: 0.3s ease;
}

.exception .swiper-slide-prev,
.exception .swiper-slide-active,
.exception .swiper-slide-next {
  opacity: 1;
}

.container {
  background: rgba(0, 159, 227, 0.2);
  /* padding: 50px 0; */
  color: #000;
  display: flex;
  flex-direction: row;
}
.container .title {
  font-size: 38px;
  font-weight: 800;
  line-height: 50px;
  color: var(--color-primary);
}
.container .cardBody {
  padding: 24px 20px;
}

.error {
  background-color: #dd4e4e;
  color: whitesmoke;
  border: solid 1px #cfcfcf;
  padding: 4px 12px;
  margin: 4px 0;
  border-radius: 6px;
}

.error:last-of-type {
  margin-bottom: 20px;
}

@media screen and (max-width: 992px) {
  .container .title {
    font-size: 32px;
    line-height: 36px;
  }
  .container .subtitle {
    font-size: 28px !important;
    line-height: 32px !important;
  }
  .container .content {
    font-size: 16px !important;
    line-height: 30px !important;
  }
  .container {
    padding-bottom: 0;
  }
  .container .cardBody {
    padding: 24px 30px;
  }
  .container .cardWrapper {
    margin-top: -35px;
  }
  .container .articoloButton {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .container .cardWrapper .cardBody .cardTitle {
    font-size: 28px !important;
  }
}
.container .subtitle {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 32px;
  color: #000;
}
.container .content {
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 0;
}
.container .taxSection {
  margin-bottom: 16px;
}
.container .articoloButton {
  background: var(--color-primary);
  color: #fff;
  border-radius: 6px;
  padding: 11px 23px;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  margin-top: 52px;
  border-style: none;
}
.container .articoloButton:hover {
  border-style: 0;
  border: 0;
  outline: 0;
  background: var(--color-secondary);
}
.container .image {
  width: 400px;
  height: 100%;
}
.container .cardWrapper {
  border: none;
  border-radius: 6px;
  box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}
.container .cardWrapper .cardBody .cardTitle {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
}

.container .cardWrapper .cardBody .cardSubtitle {
  font-weight: 600;
  font-size: 24px;
  line-height: 20px;
}

.container .cardWrapper .cardBody .description {
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
}
.container .cardWrapper .cardBody .question {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--color-primary);
}
@media screen and (max-width: 992px) {
  .container .cardWrapper .cardBody .question {
    min-height: 0px !important;
  }
}
@media screen and (max-width: 1400px) {
  .container .cardWrapper .cardBody .question {
    min-height: 40px;
  }
}
@media screen and (min-width: 600px) {
  .container .cardWrapper .cardBody .question {
    display: inline-flex;
  }
}

.container .cardWrapper .cardBody .input {
  border: 2px solid rgba(0, 158, 226, 0.2);
  box-sizing: border-box;
  border-radius: 10px;
}

.container .cardWrapper .cardBody .buttonContainer .button {
  background: var(--color-primary);
  border: none;
  box-sizing: border-box;
  border-radius: 6px;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  padding: 12px 0;
  color: #fff;
}

.text {
  font-size: 18px;
  line-height: 32px;
  font-weight: 400;
}

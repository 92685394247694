.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 0px 48px;
  position: relative;
  .sliderContainer {
    max-width: 540px;
    width: 100%;
    margin: auto;
    align-items: center;
    border-radius: 6px;
    box-shadow: 0 20px 15px 0 rgb(0 0 0 / 4%), 0 13.3px 13.8px 0 rgb(0 0 0 / 8%),
      0 6.7px 12px 0 rgb(0 0 0 / 6%);
    .iconContainer {
      cursor: pointer;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      &.rightIcon {
        right: 0;
      }
      &.leftIcon {
        left: 0;
      }
      .icon {
        height: 48px;
      }
    }
    .sliderContent {
      flex: 1;
      overflow: hidden;
      .sliderWrapper {
        .imageContainer {
          position: relative;
          transition: 0.3s ease;
          overflow: hidden;
          .image {
            transition: 0.3s ease;
            height: 100%;
            width: 100%;
            border-radius: 6px;
          }
        }
      }
    }
  }
}

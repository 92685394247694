@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap');

.container {
  .imageContainer {
    position: relative;
    .image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
    }
    .textOverlay {
      display: flex;
      align-items: center;
      z-index: 1;
      padding: 140px 0;
      background-image: linear-gradient(
        116deg,
        #000 -31%,
        rgba(0, 0, 0, 0) 62%
      );
      @media (max-width: 765px) {
        padding: 80px 0;
      }
      .textContainer {
        font-family: 'Poppins', sans-serif;
        font-size: 50px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        color: #fff;
        @media (max-width: 992px) {
          br {
            display: none;
          }
        }
      }
      .text {
        margin: 0 1px 0 0;
      }
    }
  }
  .content {
    background-color: var(--color-primary);
    padding: 24px 0;
    @media only screen and (max-width: 768px) {
      padding: 8px 0;
    }
  }

  @media (max-width: 765px) {
    .textContainer {
      font-size: 40px !important;
      text-align: center;
    }
  }

  @media (max-width: 411px) {
    .textContainer {
      font-size: 30px !important;
    }
  }
}

.container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.container > * {
    margin: 0 10px;
}

.arrow {
    height: 20px;
    cursor: pointer;
}

.itemContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius: 6px;
    border: solid 1px #979797;
    color: var(--color-text) !important;
    cursor: pointer;
}

.active {
    background-color: rgba(0, 159, 227, 0.2);
    cursor: default;
}

.itemNumber {
    padding: 0;
    margin: 0;
    font-weight: 700;
}

.cardContainer {
  width: 100%;
  overflow: hidden;
  border-radius: 6px;
  color: var(--color-text);
  background-color: var(--color-background);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
}

.imageContainer {
  position: relative;
  padding-top: 56.25%;
  overflow: hidden;
}

.imageContainer > .image {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  object-fit: cover;
  width: 100%;
}

.playIcon {
  position: absolute;
  background-color: var(--color-background);
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  opacity: 1;
  left: 50%;
  height: 48px;
  width: 48px;
  border-radius: 40px;
  padding: 12px;
  overflow: hidden;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.playIcon > svg {
  height: 100%;
  position: relative;
  left: 2px;
  width: auto;
}

.playIcon > img {
  height: 100%;
  position: relative;
  width: auto;
}

.cardContent {
  padding: 10px;
}

.title {
  display: block;
  height: 54px;
  margin-top: 0px;
  font-weight: 600;
  font-size: 16px;
  padding-right: 30px;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  margin-bottom: 12px;
}

.blue {
  color: var(--color-background) !important;
  background-color: var(--color-primary);
}

.yellow {
  color: var(--color-background) !important;
  background-color: var(--color-secondary);
}

.white {
  background-color: var(--color-background);
}

.blue > h3 > .title {
  color: var(--color-background) !important;
}

.white > h3 > .title {
  color: var(--color-text) !important;
}

.date {
  font-size: 0.6rem;
  text-align: right;
  margin: 0;
}

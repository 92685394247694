.directoryContainer {
  width: 100%;
  display: flex;
  align-items: center;
}

.origin {
  font-size: 10px;
  margin-top: 10px;
  margin-bottom: -20px;
  font-weight: 400;
}

.pathLink {
  font-size: 0.7rem;
  font-weight: 600;
  text-transform: capitalize;
  color: var(--color-primary) !important;
}

.pathLink:last-of-type {
  font-weight: 400;
}

.separator {
  height: 0.7rem;
  margin: 0 15px;
}

.postColumn {
  padding: 0 80px;
}

.postColumn:first-of-type {
  padding-left: 0;
}

.postColumn:last-of-type {
  padding-right: 0px;
}

.mainTitle {
  color: var(--color-text);
  margin-top: 30px;
  margin-bottom: 20px;
  font-weight: 800;
  font-size: 50px;
  line-height: 58px;
}

.doubleSideFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.info {
  font-size: 0.8rem;
}

.sectionTitle {
  margin: 40px 0;
  font-weight: 700;
  font-size: 2.2rem;
}

.mainImage {
  width: 100%;
  margin-top: 24px;
  border-radius: 6px;
  overflow: hidden;
}

.description {
  margin-top: 60px;
  font-size: 28px;
  line-height: 32px;
  margin-bottom: 30px;
  font-weight: 600;
}

.mapContainer {
  margin-top: 30px;
  margin-bottom: 46px;
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
}

.mapContainer > li {
  color: var(--color-primary);
  margin: 12px 0;
}

.mapContainer > li > a:hover {
  color: var(--color-secondary);
}

.postWraper > h3 {
  margin-top: 60px;
  margin-bottom: 20px;
  font-size: 28px;
  line-height: 32px;
  font-weight: 600;
  scroll-margin-top: 240px;
  @media (max-width: 992px) {
    scroll-margin-top: 200px;
  }
}

.postWraper > p,
.postWraper > ul {
  font-size: 18px;
  line-height: 32px;
  margin: 20px 0;
}

.postWraper img {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

.postWraper iframe {
  width: 100%;
  height: 400px;
}

.postWraper figure {
  width: 100% !important;
}

.container {
  padding-right: 12px !important;
  padding-left: 12px !important ;
}

.postWraper a {
  color: var(--color-primary);
}

.postWraper a:hover {
  color: var(--color-secondary);
}

.postWraper b,
.postWraper strong {
  font-weight: 600 !important;
}

.tagContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 60px 0;
}

.tag {
  color: var(--color-primary);
  border: solid 2px var(--color-primary);
  font-size: 1rem;
  font-weight: 600;
  padding: 8px 20px;
  margin: 5px 10px;
  border-radius: 100px;
}

.tag:hover {
  color: var(--color-secondary);
  border-color: var(--color-secondary);
}

.message {
  background: rgba(0, 159, 227, 0.15);
  border-radius: 6px;
  padding: 40px 60px;
  font-weight: 600;
}

.messageTitle {
  font-size: 1.2rem;
  margin-bottom: 28px;
}

.error {
  background-color: #dd4e4e;
  color: whitesmoke;
  border: solid 1px #cfcfcf;
  padding: 4px 12px;
  margin: 4px 0;
  border-radius: 6px;
  text-transform: capitalize;
}

.error:last-of-type {
  margin-bottom: 20px;
}

.succes {
  background-color: #4edd55;
  color: whitesmoke;
  border: solid 1px #cfcfcf;
  padding: 4px 12px;
  margin: 4px 0;
  border-radius: 6px;
  margin-bottom: 20px;
}

.message > input {
  color: var(--color-primary);
  margin-bottom: 24px;
  border-radius: 6px;
  font-size: 1rem;
  padding: 8px 15px;
  display: block;
  outline: none;
  border: none;
  width: 100%;
}

.message > input::placeholder {
  color: var(--color-primary);
}

.message > textarea {
  color: var(--color-primary);
  border-radius: 6px;
  font-size: 1rem;
  padding: 8px 15px;
  display: block;
  outline: none;
  border: none;
  width: 100%;
}

.message > textarea::placeholder {
  color: var(--color-primary);
}

.messageButton {
  padding: 6px 60px;
  font-weight: 600;
  font-size: 1.2rem;
  border-radius: 6px;
  border: none;
  background-color: var(--color-primary);
  color: var(--color-background);
}

.messageButton:disabled {
  opacity: 0.6;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.messageButton > svg {
  height: 20px;
  width: auto;
  animation: rotate 2s infinite linear;
}

.verticalFlex {
  display: flex;
  flex-direction: column;
}

.mondoHrButton {
  font-size: 18px;
  line-height: 20px;
  padding: 10px 30px;
  border-radius: 6px;
  font-weight: 600;
  color: var(--color-background) !important;
  background: var(--color-primary);
}

.mondoHrButton:hover {
  background: var(--color-secondary);
}

@media (max-width: 1200px) {
  .postColumn {
    padding: 0 40px !important;
  }
}

@media (max-width: 768px) {
  .postColumn {
    padding: 0 20px !important;
    position: relative;
  }

  .doubleSideFlex {
    margin-bottom: 0;
  }

  .mainImage {
    margin-top: 0;
  }

  .container {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .mainTitle {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 0;
  }

  .description {
    font-size: 22px;
    line-height: 30px;
  }

  .mapContainer > li {
    font-size: 16px;
    line-height: 24px;
  }

  .tagContainer {
    padding-top: 0px;
  }

  .message {
    padding: 40px 20px;
  }
}
@media only screen and (min-width: 1190px) and (max-width: 1270px) {
  .postarticle {
    padding-left: 33px !important;
    padding-right: 40px;
  }

  .postrightside {
    padding-left: 40px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1450px) {
  .postarticle {
    padding-left: 33px !important;
    padding-right: 40px;
  }

  .postrightside {
    padding-left: 40px;
  }
}

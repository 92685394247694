.cardContainer {
    width: 100%;
    overflow: hidden;
    border-radius: 6px;
    color: var(--color-text);
    background-color: var(--color-background);
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
}

.imageContainer {
    width: 100%;
    height: 400px;
}

.imageContainer div {
    height: 100%;
}

.imageContainer img {
    height: 100%;
    object-position: center;
    object-fit: cover;
}

.cardContent {
    padding: 30px;
}

.title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin: 10px auto;
    display: block;
    height: 96px;
    color: var(--color-text) !important;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
}

.content {
    display: -webkit-box;
    position: relative;
    -webkit-box-orient: vertical;
    margin-top: 20px;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 20px;
    height: 120px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--color-text) !important;
    -webkit-line-clamp: 6;
}

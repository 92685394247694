.container {
  position: fixed;
  z-index: 2000;
  height: calc(100% - 220px);
  width: 100%;
  background-color: var(--color-background);
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 992px) {
    height: calc(100% - 160px);
  }
}

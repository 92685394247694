.title {
  font-size: 48px;
  font-weight: 900;
  text-align: center;
  margin-top: 32px;
  color: var(--color-primary);
  margin-bottom: 48px;
}

.content b {
  color: var(--color-primary);
}

.content > b {
  margin-bottom: 2.2em;
  display: block;
}

.content a {
  color: var(--color-primary);
}

.content p {
  margin-bottom: 24px;
}

.content h3 {
  font-size: 24px;
  margin-bottom: 32px;
  color: var(--color-primary);
  font-weight: 900;
}

.content ul {
  list-style: none;
  margin-bottom: 24px;
}

.content ul li {
  margin-bottom: 12px;
}

.cardContainer {
  width: 100%;
  overflow: hidden;
  border-radius: 6px;
  color: var(--color-text);
  background-color: var(--color-background);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
  .imageContainer {
    width: 100%;
    padding-top: 56.25%;
    position: relative;
    .image {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .cardContent {
    padding: 20px;
    padding-bottom: 12px;
    .data {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .category {
        text-transform: uppercase;
        color: var(--color-secondary) !important;
        font-weight: 700;
        font-size: 0.6rem;
        letter-spacing: 1.8px;
      }
      .date {
        font-size: 0.6rem;
        margin: 0;
      }
    }
    .title {
      display: block;
      color: var(--color-text) !important;
      margin-top: 10px;
      font-size: 16px;
      height: 72px;
      font-weight: 600;
      line-height: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 4;
      margin-bottom: 10px;
    }
    .content {
      display: -webkit-box;
      margin-bottom: 0;
      font-size: 14px;
      line-height: 20px;
      height: 140px;
      font-weight: 400;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 7;
      -webkit-box-orient: vertical;
      color: var(--color-text) !important;
    }
    .duration {
      font-size: 0.7rem;
      text-align: right;
      font-weight: 600;
      margin: 0;
      margin-top: 6px;
    }
  }
  @media (max-width: 992px) {
    &.mobile {
      display: grid;
      grid-template-columns: 2fr 4fr;
      .imageContainer {
        height: 100%;
        width: 100%;
      }
      .content {
        display: none;
      }
    }
  }
}

@media (min-width: 992px) {
  .desktop {
    display: flex;
    flex-direction: column;
    .cardContent {
      display: flex;
      padding: 0 20px;
      padding-bottom: 10px;
      flex-direction: column;
      .data {
        order: 2;
        justify-content: flex-end;
        .category {
          display: none;
        }
      }
      .duration {
        display: none;
      }
      .content {
        display: none;
      }
    }
  }
}

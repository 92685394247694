.container {
    padding: 40px 32px;
}

.bgWrapper {
    background: #ffffff;
    box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05),
        0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 40px 32px;
}

.title {
    font-weight: 700;
    font-size: 28px;
    line-height: 40px;
    color: black;
    margin-bottom: 24px;
}

.subtitle {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: #000000;
    margin-bottom: 16px;
}

.response {
    color: rgba(60, 170, 130, 1);
    font-weight: 700;
    font-size: 54px;
    line-height: 66px;
    text-align: center;
    margin: 48px 0;
}

.buttonWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 48px;
}
.calcolaButton {
    background: var(--color-primary);
    border: none;
    color: #fff;
    padding: 11px 41px;
    border-radius: 8px;
    /* opacity: 0.4; */
}

@media screen and (max-width: 992px) {
    .contentWrapper {
        flex-direction: column;
        justify-content: center;
    }
    .textFiledsWrapper {
        max-width: 100% !important;
    }
    .fieldsTitle {
        width: 100%;
    }
    .sliderWrapper {
        width: 100% !important;
        margin-top: 20px;
        margin-left: 0;
    }
    .input {
        width: 100% !important;
    }
    .rangeSlider {
        margin-top: -10px;
    }
}

@media only screen and (max-width: 992px) {
    .title {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 10px;
    }
    .subtitle {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 10px;
    }
}

.button {
    padding: 6px 36px;
    font-weight: 600;
    font-size: 1.2rem;
    border-radius: 6px;
    border: none;
}

.blue {
    background-color: var(--color-primary);
    color: var(--color-background);
}

.white {
    background-color: var(--color-background);
    color: var(--color-primary);
}

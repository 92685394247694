.container {
  padding: 30px 20px;
  max-width: 1100px;
  width: 100%;
  margin: auto;
  display: grid;
  grid-template-columns: 70px auto 260px;
  align-items: center;
}

.video {
  background-color: var(--color-primary);
  color: var(--color-primary);
}

.book {
  background-color: var(--color-secondary);
  color: var(--color-secondary);
}

.book > .container > .buttonContainer > .button {
  color: var(--color-secondary) !important;
}
.book > .container > .buttonContainer > .button:hover {
  color: var(--color-primary) !important;
}
.video {
  background-color: var(--color-primary);
  color: var(--color-primary);
}

.video > .container > .buttonContainer > .button {
  color: var(--color-primary) !important;
}

.video > .container > .buttonContainer > .button:hover {
  color: var(--color-secondary) !important;
}

.videoContainer {
  padding: 30px 15vw;
  background-color: var(--color-primary);
  display: grid;
  grid-template-columns: auto auto 300px;
  align-items: center;
  color: var(--color-primary);
}

.icon {
  grid-row: 1/3;
  background-color: var(--color-background);
  height: 70px;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 35px;
}

.icon > svg {
  width: 20px;
  height: auto;
}

.book > .container > .icon > svg {
  width: 40px;
  height: auto;
}

.book > .container > .icon > svg > g > path {
  fill: var(--color-secondary);
}

.container > h2 {
  color: var(--color-background);
  font-weight: 700;
  font-size: 2rem;
  grid-column: 2/3;
  margin: 0 40px;
}

.container > h3 {
  color: var(--color-background);
  font-weight: 600;
  font-size: 1rem;
  grid-column: 2/3;
  margin: 0 40px;
}

.buttonContainer {
  grid-row: 1/3;
  grid-column: 3/4;
}

.button {
  padding: 8px 36px;
  font-weight: 600;
  font-size: 1.2rem;
  border-radius: 6px;
  width: 100%;
  max-width: 260px;
  display: block;
  text-align: center;
  border: none;
  margin: 20px auto;
  background-color: var(--color-background);
  color: inherit;
}

@media (max-width: 600px) {
  .container {
    grid-template-columns: auto 1fr;
    align-items: center;
  }
  .icon {
    grid-row: 2/3;
    grid-template-rows: 1/2;
  }
  .container > h2 {
    grid-row: 1/2;
    grid-column: 1/3;
    font-size: 22px;
    line-height: 40px;
    margin: 0;
    text-align: center;
  }
  .container > h3 {
    grid-row: 2/3;
    grid-column: 2/3;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 18px;
    margin-left: 10px;
  }
  .buttonContainer {
    grid-column: 1/3;
    grid-row: 3/4;
  }
  .button {
    font-size: 16px;
  }
  .icon {
    margin: 0 20px;
    height: 60px;
    width: 60px;
  }
}

.container {
  display: block;
  border-radius: 6px;
  padding: 32px 30px;
  background-color: var(--color-background);
  box-shadow: 0 20px 15px 0 rgba(0, 0, 0, 0.04),
    0 13.3px 13.8px 0 rgba(0, 0, 0, 0.08), 0 6.7px 12px 0 rgba(0, 0, 0, 0.06);
  .title {
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;
  }
  .image {
    width: 100%;
    border-radius: 6px;
    margin-bottom: 40px;
  }
  .productTitle {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    height: 48px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 16px;
  }
  .productDescription {
    font-size: 14px;
    line-height: 20px;
    height: 100px;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 0;
  }
}

.directoryContainer {
  width: 100%;
  display: flex;
  align-items: center;
}

.pathLink {
  font-size: 0.7rem;
  font-weight: 600;
  text-transform: capitalize;
  color: var(--color-primary) !important;
}

.pathLink:last-of-type {
  font-weight: 400;
}

.separator {
  height: 0.7rem;
  margin: 0 15px;
}

.postColumn {
  padding: 0 80px;
}

.postColumn:last-of-type {
  padding-right: 0px;
}

.mainVideo {
  width: 100%;
  height: auto;
  margin-top: 50px;
  border-radius: 6px;
}

.mainTitle {
  color: var(--color-text);
  margin-top: 30px;
  margin-bottom: 20px;
  font-weight: 800;
  font-size: 50px;
  line-height: 58px;
}

.sectionDescription {
  font-size: 1rem;
  line-height: 1.6em;
  font-weight: 400;
  margin-bottom: 2rem;
}

.doubleSideFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.category {
  text-transform: uppercase;
  color: var(--color-secondary) !important;
  font-weight: 700;
  font-size: 0.6rem;
  letter-spacing: 1.8px;
}

.date {
  font-size: 0.6rem;
}

.sectionTitle {
  margin: 30px 0;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 2rem;
}

.moreArticles {
  color: var(--color-primary);
  text-decoration: underline;
  text-align: right;
}
.moreArticles:hover {
  color: var(--color-secondary);
}
.verticalFlex {
  display: flex;
  flex-direction: column;
}

.videoContainer {
  margin-top: 40px;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .videoContainer {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}

.videoContainer > img {
  height: 100%;
  width: 100%;
  transition: 0.3s ease-in-out;
}

.playIcon {
  position: absolute;
  background-color: var(--color-background);
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  top: 50%;
  left: 50%;
  height: 80px;
  width: 80px;
  border-radius: 40px;
  padding: 22px;
  overflow: hidden;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  z-index: 2;
}

.playIcon > svg {
  position: relative;
  height: 100%;
  left: 4px;
  width: auto;
}

.playIcon > img {
  position: relative;
  height: 100%;
  width: auto;
}

.imageContainer {
  transition: 0.3s easee-in-out;
}

.image {
  width: 100%;
}

.playing > .playIcon,
.playing > .imageContainer {
  opacity: 0 !important;
}

.playing > .videoWraper > iframe {
  z-index: 2;
}

.videoWraper > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition-delay: 0.3s;
}

.postWraper > h3 {
  margin-top: 60px;
  margin-bottom: 20px;
  font-size: 28px;
  line-height: 32px;
  font-weight: 600;
}

.postWraper > p,
.postWraper > ul {
  font-size: 18px;
  line-height: 32px;
  margin: 20px 0;
}

.postWraper img {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

.postWraper iframe {
  width: 100%;
  height: 400px;
}

.postWraper figure {
  width: 100% !important;
}

.postWraper a {
  color: var(--color-primary);
}

.postWraper a:hover {
  color: var(--color-secondary);
}

.postWraper b,
.postWraper strong {
  font-weight: 600 !important;
}

.tagContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 60px 0;
}

.tag {
  color: var(--color-primary);
  border: solid 2px var(--color-primary);
  font-size: 1rem;
  font-weight: 600;
  padding: 8px 20px;
  margin: 5px 10px;
  border-radius: 100px;
}

.tag:hover {
  color: var(--color-secondary);
  border-color: var(--color-secondary);
}

.instagramVideoCont {
  display: flex;
}

.instagramVideoCont iframe {
  height: 870px !important;
  margin: auto !important;
  position: initial;
}

@media (max-width: 768px) {
  .postColumn {
    padding: 0 20px !important;
  }

  .mainTitle {
    font-size: 30px;
    line-height: 36px;
  }

  .tagContainer {
    padding-top: 0px;
  }
}

.bannerComponentContainer{

}

.bannerComponentInner{
  position:relative;
  padding: 0;
  background: #fff;
  margin-left:10px;
  margin-right:10px;
  margin-bottom:10px;
}

.barcodeContainer{
  position:fixed;
  top:0;
  right:0;
  bottom:0;
  left:0;
  background:rgba(0, 0, 0, 0.50);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index:99999999;
  padding:10px;
}
.barcodeWrapper{
  text-align: center;
  position:relative;
  top:0;
  right:0;
  bottom:0;
  left:0;
  background:#fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index:9;
}
.barcodeComponent{
  max-width:100%;
}

.bannerImg{
  display:block;
  margin:0 auto;
  cursor:pointer;
  max-width:100%;
}
.closeButton{
  position: absolute;
  top:-40px;
  right:-6px;
  width:36px;
  height:36px;
  border-radius: 100%;
  overflow:hidden;
  color: #fff;
  cursor: pointer;
}
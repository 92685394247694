.container {
  color: var(--color-background);
  background-color: var(--color-primary);
  .title {
    padding: 32px 0;
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;
    margin-bottom: 0;
    text-align: center;
    @media (max-width: 340px) {
      font-size: 26px;
      line-height: 32px;
      font-weight: 700;
    }
  }
  .sliderContainer {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    position: relative;
    &::after {
      position: absolute;
      height: 50%;
      width: 100vw;
      transform: translateX(-50%);
      background-color: var(--color-background);
      content: "";
      bottom: 0;
      left: 50%;
    }
    .iconContainer {
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: 0.3s ease;
      z-index: 1;
      @media (max-width: 320px) {
        width: 30px;
        height: 30px;
      }
      @media (max-width: 400px) {
        width: 50px;
        height: 50px;
      }
      @media (max-width: 440px) {
        width: 50px;
        height: 50px;
      }
    }
    .sliderContent {
      flex: 1;
      overflow: hidden;
      .sliderWrapper {
        width: 100%;
        .slider {
          padding: 32px 12px;
        }
      }
    }
  }
}

.container {
    /* padding: 40px 32px; */
    padding: 15px !important;
}

.bgWrapper {
    background: #ffffff;
    box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05),
        0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 40px 32px;
}

.title {
    font-weight: 700;
    font-size: 28px;
    line-height: 40px;
    color: black;
    margin-bottom: 24px;
}

@media only screen and (max-width: 992px) {
    .title {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 10px;
    }
    .subtitle {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 10px;
    }
    .legendWrapper {
        padding: 15px 0 15px 0 !important;
    }
    .text {
        padding-top: 20px !important;
    }
    /* .legendTitle {
        white-space: normal !important;
    } */
}
@media only screen and (max-width: 768px) {
    /* .legendTitle {
        white-space: nowrap !important;
    } */
    .legendContent {
        border-bottom: 1px #bcbcbc solid;
        padding-bottom: 20px;
        padding-top: 20px;
    }

    .legendContent1 {
        border-bottom: none !important;
        /* margin-top: 20px; */
    }
    .legendNetto:last-child {
        margin-bottom: 15px;
    }
}
@media (min-width: 1201px) and (max-width: 1399px) {
    .divider {
        margin: 0 15px 0 15px !important;
    }
    .legendTitle {
        max-width: 130px !important;
    }
    .netto {
        font-size: 15px !important;
    }
}
.subtitle {
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #000000;
    margin-bottom: 16px;
}

.legendWrapper {
    padding: 35px 0 35px 0;
}
.legendContainer {
    /* display: flex;
    align-items: center; */
    border-bottom: 1px solid #bcbcbc;
    padding-bottom: 32px;
    margin-bottom: 32px;
}
.legendContent {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
}
.legendSquare {
    background: #0263ff;
    border-radius: 4px;
    transform: rotate(90deg);
    width: 16px;
    height: 16px;
    margin-right: 8px;
}

.legendTitle {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin-right: 24px;
    max-width: 200px;
    min-width: 200px;
}

.legendNetto {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #bcbcbc;
}
.netto {
    color: #000000;
    font-size: 17px;
    margin-left: 5px;
}
.netto1 {
    color: #000000;
    font-size: 14px;
    margin-left: 5px;
}

.divider {
    width: 2px;
    height: 22px;
    background: #bcbcbc;
    margin: 0 32px 0 32px;
}

.legendSubtitle {
    display: flex;
    align-items: center;
}

.text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    padding-top: 32px;
}
.buttonWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
}
.calcolaButton {
    background: var(--color-primary);
    border: none;
    color: #fff;
    padding: 11px 41px;
    border-radius: 8px;
    /* opacity: 0.4; */
}
@media only screen and (max-width: 1200px) {
    /* .legendContent {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    } */
    .divider {
        display: none;
        margin: 0 25px 0 25px;
    }
    /* .legendTitle {
        white-space: nowrap;
        margin-right: 18px;
    } */
    .legendNetto {
        font-size: 13px;
        margin-right: 8px;
    }
    .netto {
        font-size: 13px;
        /* margin-right: 8px; */
    }
    .netto1 {
        font-size: 13px;
        white-space: nowrap;
    }
}
.wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.nowrap {
    white-space: nowrap;
}
@media only screen and (max-width: 768px) {
    .legendContent {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 1px !important;
    }
    .legendSubtitle {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        /* justify-content: center; */
    }
    .wrap {
        padding-bottom: 10px;
    }
    .legendNetto {
        padding-bottom: 10px;
    }
}

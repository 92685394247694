.directoryContainer {
    width: 100%;
    display: flex;
    align-items: center;
}

.pathLink {
    font-size: 14px;
    font-weight: 600;
    text-transform: none;
    color: var(--color-primary) !important;
}

span:last-of-type > .pathLink {
    font-weight: 400;
}

.separator {
    height: 14px;
    margin: 0 15px;
}

.dropDownWrapper {
  background-color: var(--color-background);
}

.dropDownWrapper.disabled .title {
  opacity: 0.2;
}

.dropDownHead {
  display: flex;
  align-items: center;
  padding: 8px 0;
  border-bottom: solid 2px var(--color-primary);
  cursor: pointer;
}

.dropDownWrapper.disabled .dropDownHead {
  border-bottom: solid 2px rgba(0, 159, 227, 0.4);
}

.activeHead .icon {
  transform: scaleY(1);
}

.chapterWrapper {
  flex: 1;
}

.inactiveLabel {
  color: var(--color-background);
  background: var(--color-secondary);
  padding: 2px 8px;
  margin-right: 16px;
}

.chapter {
  font-size: 10px;
  line-height: 20px;
  letter-spacing: 2px;
  font-weight: 700;
  color: var(--color-secondary);
  text-transform: uppercase;
  margin-bottom: 2px;
  white-space: nowrap;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
  margin-bottom: 0;
}

.duration {
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 1px;
  color: var(--color-grey);
  margin-bottom: 0;
}

.icon {
  transition: 0.3s ease;
  margin-left: 94px;
  transform: scaleY(-1);
}

.dropDownBody {
  padding: 32px;
  display: none;
}

.dropDownBodyActive {
  display: block;
  box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.subtitle {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 24px;
  font-weight: 300;
}

.bodyContent {
  display: flex;
}

.contentColumn {
  flex: 1;
}

.moduleWrapper {
  margin-left: 32px;
  margin-right: 80px;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.moduleContent,
.imageWrapper {
  flex: 1;
  margin-right: 12px;
}

.moduleNumber {
  font-size: 10px;
  line-height: 20px;
  letter-spacing: 2px;
  font-weight: 700;
  color: var(--color-secondary);
  margin-bottom: 0;
}

.moduleTitle {
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
  color: var(--color-primary);
}

.moduleTitle:hover {
  color: var(--color-secondary);
  cursor: pointer;
}

@media (max-width: 768px) {
  .duration {
    font-size: 14px;
  }

  .icon {
    margin-left: 10px;
  }
}

@media (max-width: 992px) {
  .bodyContent {
    display: block;
  }

  .imageWrapper {
    margin-bottom: 32px;
    margin-right: 0;
  }

  .moduleWrapper {
    margin: 12px 0;
  }
}

.directoryContainer {
  width: 100%;
  display: flex;
  align-items: center;
}

.pathLink {
  font-size: 0.7rem;
  font-weight: 600;
  text-transform: capitalize;
  color: var(--color-primary) !important;
}

.pathLink:last-of-type {
  font-weight: 400;
}

.separator {
  height: 0.7rem;
  margin: 0 15px;
}

.mainTitle {
  color: var(--color-text);
  text-align: center;
  margin-top: 24px;
  margin-bottom: 16px;
  font-size: 50px;
  font-weight: 800;
  line-height: 58px;
  text-transform: capitalize;
}

.info {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  margin-bottom: 60px;
}

.typeContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.typeItem {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: solid 1px var(--color-primary);
  width: 120px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.typeItem > h2 {
  line-height: 24px;
  font-size: 20px;
}

.typeItem.active {
  border-width: 4px;
}

.typeItem > h6 {
  font-size: 20px;
  line-height: 24px;
}

.typeItem.active > h6 {
  font-weight: 600;
}

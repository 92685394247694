.share {
    display: none;
    background-color: var(--color-primary);
    padding-top: 20px;
    padding-bottom: 10px;
}

.iconContainer {
    display: flex;
    margin: 20px 0;
    justify-content: center;
}

.iconsTitle {
    width: 100%;
    text-align: center;
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
    color: var(--color-background);
}

.socialIconContainer {
    height: 36px;
    width: 36px;
    padding: 5px;
    margin: 0 8px;
    border: solid 2px var(--color-background);
    border-radius: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    justify-content: center;
}

.socialIcon {
    width: auto;
    height: 100%;
}

@media (max-width: 992px) {
    .share {
        display: block;
        margin-top: 16px;
    }
}

.container {
  background-color: var(--color-primary);
  padding-top: 72px;
  .logo {
    height: 32px;
    margin-bottom: 24px;
    @media (max-width: 430px) {
      height: 24px;
    }
  }
  .paragraph {
    font-size: 12px;
    line-height: 18px;
    color: var(--color-background);
  }
  .link {
    display: block;
    font-size: 14px;
    line-height: 40px;
    white-space: nowrap;
    color: var(--color-background);
    text-decoration: underline;
    @media (max-width: 992px) {
      text-align: center;
    }
  }
  .bottom {
    border-top: solid 1px var(--color-background);
    padding-top: 16px;
    padding-bottom: 20px;
    color: var(--color-background);
    @media (max-width: 992px) {
      margin-top: 48px;
    }
    .content {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 0;
      text-align: center;
      .bottomLink {
        color: var(--color-background);
      }
    }
  }
}

.cardContainer {
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
  background-color: var(--color-background);
  color: var(--color-text);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
  margin-top: 65px;
  margin-bottom: 65px;
  position: relative;
  display: block;
}
.disabled {
  position: absolute;
  padding: 25px 64px 0 64px;
  z-index: 2;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
}
.disabled::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, #000000 53.6%, rgba(0, 0, 0, 0.5) 100%);
  opacity: 0.8;
  border-radius: 0px 0px 6px 6px;
  height: 100%;
  z-index: -1;
}
.disabledContent {
  color: white;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
}
.disabledTitle {
  color: white;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  padding-bottom: 10px;
}
.strumentiButton {
  background-color: var(--color-background);
  color: var(--color-primary);
  z-index: 4;
  width: 100%;
  text-align: center;
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  padding: 12px;
  border-radius: 6px;
  margin-top: 24px;
}
.strumentiButton:hover {
  background-color: var(--color-primary);
  color: var(--color-background);
}
@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .disabled {
    padding: 25px 25px 0 25px;
  }
  .disabledTitle {
    font-size: 18px;
    line-height: 20px;
  }
  .disabledContent {
    font-size: 16px;
    line-height: 20px;
  }
}
.yellowBorder {
  padding: 8px;
  width: 100%;
  background-color: var(--color-secondary) !important;
  border-radius: 8px 8px 0px 0px;
  height: 36px;
  z-index: 1;
}
.yellowBorderTitle {
  color: white;
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 10px;
  line-height: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.imageContainer {
  width: 100%;
}

.imageContainer div {
  height: 100%;
}

.imageContainer img {
  height: 100%;
  object-position: center;
  object-fit: cover;
}

.cardContent {
  padding: 20px;
  z-index: 1;
}

.data {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.category {
  text-transform: uppercase;
  color: var(--color-secondary) !important;
  font-weight: 700;
  font-size: 0.6rem;
  letter-spacing: 1.8px;
}

.date {
  font-size: 0.6rem;
  margin: 0;
}

.title {
  display: block;
  font-size: 20px;
  line-height: 24px;
  color: var(--color-text) !important;
  height: 48px;
  font-weight: 600;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.content {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  margin-top: 20px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 20px;
  height: 100px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--color-text) !important;
  -webkit-line-clamp: 5;
}

.duration {
  font-size: 0.7rem;
  text-align: right;
  font-weight: 600;
  padding-top: 20px;
  margin: 0;
}

.container {
  position: relative;
  overflow: hidden;
  .imageContainer {
    width: 100%;
    padding-top: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 6px;
    &::after {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      content: "";
      opacity: 0.6;
      transition: 0.3s ease;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 100%
      );
    }
    .image {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: cover;
      top: 0;
    }
  }
  &:hover {
    .imageContainer {
      &::after {
        opacity: 1;
        background: var(--color-primary);
      }
    }
    .title {
      margin-bottom: 0;
      transform: translateY(100%);
    }
    .content {
      transform: translateY(0);
      opacity: 1;
    }
    .more {
      transform: translateX(0);
      opacity: 1;
    }
  }
  .title {
    position: absolute;
    width: 100%;
    padding: 0 32px;
    font-size: 22px;
    line-height: 32px;
    font-weight: 700;
    color: var(--color-background);
    bottom: 0;
    z-index: 1;
    text-align: center;
    transition: 0.3s ease;
  }
  .content {
    height: 100%;
    width: 100%;
    top: 0;
    opacity: 0;
    position: absolute;
    padding: 38px 42px;
    transform: translateY(-100%);
    transition: 0.3s ease;
    .icon {
      height: 64px;
    }
    .hoverTitle {
      font-size: 20px;
      line-height: 24px;
      height: 72px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      color: var(--color-background);
      font-weight: 600;
      margin-top: 28px;
      margin-bottom: 0;
    }
    .hoverDescription {
      font-size: 14px;
      line-height: 20px;
      height: 80px;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      color: var(--color-background);
      margin-top: 16px;
    }
  }
  .more {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 24px;
    letter-spacing: 4.2px;
    color: var(--color-background);
    padding: 12px 12px 8px 22px;
    font-weight: 600;
    border-top-left-radius: 32px;
    margin-bottom: 0;
    background-color: var(--color-secondary);
    transition: 0.3s ease;
    transform: translateX(100%);
    opacity: 0;
  }
}

.container {
  padding: 50px 0;
  border-radius: 6px;
  background-color: var(--color-primary);
  color: var(--color-background);
  .title {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 18px;
  }
  .content {
    font-size: 14px;
    margin-bottom: 0;
    line-height: 20px;
  }
  .sliderContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-left: -7px;
    @media (max-width: 992px) {
      margin-top: 35px;
    }
    &::after {
      position: absolute;
      height: 50%;
      width: 100vw;
      transform: translateX(-50%);
      content: '';
      bottom: 0;
      left: 50%;
    }
    .iconContainer {
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: 0.3s ease;
      .icon {
        height: 30px;
        position: relative;
        z-index: 1;
        fill: var(--color-background);
      }
    }
    .sliderContent {
      flex: 1;
      overflow: hidden;
      .sliderWrapper {
        width: 100%;
        .imageContainer {
          width: 100%;
          position: relative;
          padding-top: 56.25%;
          border-radius: 6px;
          overflow: hidden;
          box-shadow: 0 20px 15px 0 rgba(0, 0, 0, 0.04),
            0 13.3px 13.8px 0 rgba(0, 0, 0, 0.08),
            0 6.7px 12px 0 rgba(0, 0, 0, 0.06);
          .image {
            position: absolute;
            height: 100%;
            width: 100%;
            object-fit: cover;
            top: 0;
          }
        }
      }
    }
  }
}

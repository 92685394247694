.container {
  z-index: 3;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  overflow: hidden;
  left: 0;
  background: var(--color-primary);
}

.title {
  color: var(--color-primary);
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  background: var(--color-background);
  border-top-right-radius: 6px;
  border: solid 2px var(--color-primary);
  padding: 8px 10px;
  font-weight: 600;
  margin: 0;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 14px;
  cursor: pointer;
  .likeCount {
    color: var(--color-background);
    font-size: 10px;
    line-height: 12px;
    margin-top: 4px;
    margin-bottom: 0;
    font-weight: 600;
  }
}

.iconContainer:hover {
  background: var(--color-secondary);
}

.seperator {
  height: 1px;
  background: var(--color-background);
  width: 100%;
}

@media (max-width: 768px) {
  .wraper {
    display: flex;
  }

  .container {
    position: -webkit-sticky;
    position: sticky;
    z-index: 1;
    transform: translateY(0);
    background: var(--color-primary);
    top: 0;
    width: 100%;
    border-radius: 6px;
    display: flex;
    align-items: stretch;
    justify-content: space-evenly;
  }

  .shareVideo .container {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  .title {
    color: var(--color-background);
    background-color: var(--color-primary);
    border-radius: 6px;
    display: flex;
    align-items: center;
  }

  .iconContainer {
    padding: 16px 10px;
    flex: 1;
    text-align: center;
    flex-direction: row !important;
    .likeCount {
      margin: 0;
      margin-left: 14px;
    }
  }

  .seperator {
    width: 1px;
    background: var(--color-background);
    height: 100%;
  }
}

.messageContainer {
  position: fixed;
  bottom: 32px;
  background-color: var(--color-secondary);
  transform: translateX(-50%);
  left: 50%;
  padding: 12px 24px;
  border-radius: 6px;
  transition: 0.3s ease;
  z-index: 10;
  p {
    margin-bottom: 0;
    color: var(--color-background);
  }
}

.cardContainer {
  width: 100%;
  overflow: hidden;
  border-radius: 6px;
  color: var(--color-text);
  background-color: var(--color-background);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
  .image {
    width: 100%;
    height: 350px;
    object-fit: cover;
  }
  .cardContent {
    padding: 20px;
    padding-bottom: 10px;
    .data {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .category {
        text-transform: uppercase;
        color: var(--color-secondary) !important;
        font-weight: 700;
        font-size: 0.6rem;
        letter-spacing: 1.8px;
      }
      .date {
        font-size: 0.6rem;
      }
    }
    .title {
      display: block;
      margin-top: 20px;
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 1.1em;
      color: var(--color-text) !important;
      @media (max-width: 600px) {
        font-size: 16px;
        line-height: 18px;
      }
    }
    .content {
      display: -webkit-box;
      color: var(--color-text) !important;
      margin-top: 10px;
      margin-bottom: 0;
      font-size: 14px;
      line-height: 20px;
      margin-right: 70px;
      font-weight: 400;
      height: 60px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      @media (max-width: 600px) {
        display: none;
      }
    }
    .duration {
      font-size: 0.8rem;
      text-align: right;
      font-weight: 600;
      margin: 0;
    }
  }
}

.container {
    padding: 100px 0;
    color: #000;
    display: flex;
    flex-direction: row;
}
@media screen and (max-width: 992px) {
    .container {
        padding: 50px 0px;
    }
    .container .imageWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
    }
    .container .title {
        font-size: 28px !important;
        line-height: 32px !important;
    }
    .container .content {
        font-size: 16px !important;
        line-height: 30px !important;
    }
}
.container .title {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
}

.container .content {
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 0;
}

.container .articoloButton {
    background-color: var(--color-secondary);
    color: #fff;
    border-radius: 6px;
    padding: 12px 52px;
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    margin-top: 52px;
    border-style: none;
}
.articoloButton:hover {
    background-color: var(--color-primary);
}
